<template>
    <div class="flex items-center justify-center h-[100vh]">
        <div class="text-center">
            <div class="text-6xl font-black">404</div>
            <div class="text-2xl font-light mt-2">La page que vous cherchez n'existe pas</div>
            <img class="h-[100px] mx-auto mt-10" src="https://i.ibb.co/gb2TvRn/Logo-novice-en-cuisine-V10.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>