<template>
    <div>
        <admin-template>
            <div class="bg-white rounded shadow p-6">
                <h1 class="text-2xl font-bold mb-4">A la une</h1>
                <div v-for="(recette, recetteIndex) in recettes" :key="'recette-' + recetteIndex" class="py-4 flex items-center hover:bg-gray-50 duration-200">
                    <div class="font-bold w-[150px]">
                        Recette {{ recetteIndex + 1 }}
                    </div>
                    <multiselect
                    @input="saveRecette(recetteIndex)"
                    v-model="recette.recette"
                    @search-change="(search) => {searchRecette(search, recetteIndex)}"
                    class="mt-1"
                    :options="recette.results"
                    label="nom"
                    :max-height="200"
                    track-by="id"
                    />
                </div>
            </div>
        </admin-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            recettes: [
                {results: [], recette: null},
                {results: [], recette: null},
                {results: [], recette: null},
                {results: [], recette: null},
            ]
        }
    },
    methods: {
        async getTopRecettes() {
            let response = await this.$request.post('/recettes/get-top')
            let recettes = response.data.api
            recettes.forEach((recette) => {
                this.recettes[recette.index].recette = recette.recette
            })
        },
        async searchRecette(search, index) {
            let response = await this.$request.post('/recettes/search', {
                search: search
            })
            this.recettes[index].results = response.data.api
        },

        async saveRecette(index) {
            let response = await this.$request.post('/recettes/update-top', {
                index: index,
                recette_id: this.recettes[index].recette.id
            })
            if (response.data.success == 0) {
                this.$toast.error(response.data.message)
            } else {
                this.$toast.success('Modification enregistrée')
            }
        }
    },
    mounted () {
        this.getTopRecettes();
    },
}
</script>

<style lang="scss" scoped>

</style>