<template>
    <div>
        <div @click="$router.push({name: 'recette', params: {slug: recette.url_slug}})" class="mb-4">
            <!-- Large screen -->
            <div class="hidden md:block">
                <div
                class="border relative max-w-xs overflow-hidden bg-cover bg-no-repeat rounded h-[300px] cursor-pointer bg-gray-50"
                style="background-position: center center;"
                data-te-ripple-init
                data-te-ripple-color="light"
                >
                    <img v-if="recette.images && recette.images.length > 0" :src="recette.images[0].url" class="max-w-xs" alt="" loading="lazy" />
                    <img v-else="recette.images && recette.images.length > 0" src="https://i.ibb.co/gb2TvRn/Logo-novice-en-cuisine-V10.png" loading="lazy" class="max-w-xs" alt="" />
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                </div>
                <div class="text-default text-sm mt-4">
                    <template v-for="(recetteCateg, recetteCategIndex) in recette.sous_categories">
                        {{ recetteCateg.nom + (recetteCategIndex < recette.sous_categories.length - 1 ? ', ' : '') }}
                    </template>
                </div>
                <div class="text-lg opacity-90">{{ recette.nom }}</div>
            </div>
            <!-- Small screen -->
            <div class="md:hidden flex items-center gap-2">
                <img v-if="recette.images && recette.images.length > 0" :src="recette.images[0].url" class="max-w-xs h-[100px] rounded" alt="" loading="lazy" />
                <div class="text-lg opacity-90">{{ recette.nom }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        recette: {required: true},
    },
}
</script>

<style lang="scss" scoped>

</style>