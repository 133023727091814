<template>
    <div>
        <div class="p-5 bg-gray-200 p-2">
            <div class="bg-white flex justify-between items-center p-4 rounded">
                <h2 class="text-xl font-bold">Imprimer la recette</h2>
                <div class="flex items-center gap-4">
                    <button @click="exportToPDF" class="px-4 py-2 bg-green-200 hover:bg-green-300 duration-200 rounded"><i class="fas fa-print mr-1"></i> Imprimer</button>
                    <button @click="$modal.hide('print-pdf')"><i class="text-xl fas fa-times text-red-500"></i></button>
                </div>
            </div>
        </div>

        <div v-if="recette" id="element-to-convert" class="bg-white h-auto" style="width: 21cm">
            <div class="flex">
                <div class="top-0 bottom-0 w-[30%] height-[100%] p-4" style="background: #f9d7d5;">
                    <h1 class="text-xl font-bold">Novice en cuisine</h1>
                    <h3 class="font-light text-sm mt-1">Blogueuse culinaire et conseillère Guy Demarle</h3>
                    <div class="mt-4 border-t pt-4" style="border-color: #c79b99;">
                        <h3 class="font-light text-sm">https://noviceencuisine.com</h3>
                        <h3 class="font-light text-sm mt-1">marionnette01@hotmail.com</h3>
                    </div>
    
                    <div class="mt-4 border-t pt-4" style="border-color: #c79b99;">
                        <h1 class="font-bold mb-2">Ingrédients</h1>
                        <div class="mb-1 text-sm font-light" v-for="(ingredient, ingredientIndex) in recette.ingredients" :key="'ingredient-' + ingredientIndex">
                            {{ ingredient }}
                        </div>
                    </div>
    
                    <div class="mt-4 border-t pt-4 pb-[50px]" style="border-color: #c79b99;">
                        <h1 class="font-bold mb-2">Ustensiles</h1>
                        <div class="mb-1 text-sm font-light" v-for="(ustensile, ustensileIndex) in recette.ustensiles" :key="'ustensile-' + ustensileIndex">
                            {{ ustensile.nom }}
                        </div>
                    </div>
                </div>
                <div class="w-[70%] left-[30%] top-0 bottom-0 p-4">
                    <h1 class="text-3xl mb-4 border-b-2 pb-5">{{ recette.nom }}</h1>

                    <div class="grid grid-cols-3 gap-4 my-6">
                        <div v-for="(img, imgIndex) in recette.images" :key="'img-' + imgIndex">
                            <img class="w-full rounded" :src="img.url">
                        </div>
                    </div>

                    <h4 class="text-lg font-bold mb-2">Introduction</h4>
                    <p class="mb-5 font-light text-sm" v-html="recette.description"></p>
    
                    <div class="mt-10 grid grid-cols-3 gap-5 border-b pb-6">
                        <div>
                            <div class="border-r text-center px-4">
                                <i class="far fa-clock text-xl mb-1.5"></i>
                                <div class="font-semibold">Préparation</div>
                                <div class="font-light text-sm">{{ recette.temps_preparation }}</div>
                            </div>
                        </div>
                        <div>
                            <div class="border-r text-center px-4">
                                <i class="fas fa-blender text-xl mb-1.5"></i>
                                <div class="font-semibold">Cuisson</div>
                                <div class="font-light text-sm">{{ recette.temps_cuisson }}</div>
                            </div>
                        </div>
                        <div>
                            <div class="text-center px-4">
                                <i class="fas fa-bars-progress text-xl mb-1.5"></i>
                                <div class="font-semibold">Quantité</div>
                                <div class="font-light text-sm">{{ recette.qtt }}</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="border-b pb-6">
                        <h4 class="text-lg font-bold mt-5 mb-2">Réalisation de la recette</h4>
                        <p class="font-light text-sm" v-html="recette.deroulement"></p>
                    </div>

                    <h4 class="text-lg font-bold mt-5 mb-2">Où me retrouver ?</h4>
                    <div class="text-sm font-light">
                        <div>E-mail: marionnette01@hotmail.com</div>
                        <div>Site internet: https://noviceencuisine.com</div>
                        <div>Facebook: https://facebook.com/MarionnetteNovicencuisine</div>
                        <div>Instagram: https://instagram.com/novice_en_cuisine</div>
                    </div>

                    <div class="h-[40px]"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
    props: {
        slug: {required: true},
    },
    data() {
        return {
            recette: null
        }
    },
    methods: {
        async exportToPDF() {
            var element = document.getElementById('element-to-convert');

            let file = await html2pdf().set({
                filename: (this.recette.url_slug + '.pdf').toLocaleLowerCase(),
                html2canvas: { scale: 2,useCORS: true },
            }).from(element).toPdf().save()

            // let file = await html2pdf().set({
            //     filename: this.recette.url_slug + '.pdf',
            //     html2canvas: { scale: 2,useCORS: true },
            // }).from(element).toPdf().output('blob').then((data) => {
            //     return data
            // })
            // var bodyFormData = new FormData();
            // bodyFormData.append('file', file);
            // bodyFormData.append('key', 'recettes-pdf');

            // this.fileUrl = null
            // let response = await this.$request.post('/file/add', bodyFormData)
            // console.log(response.data.api.url);
        },

        async getRecette() {
            let response = await this.$request.post('/recette/get', {
                slug: this.slug
            })
            if (!response.data.success) {
                // this.$toast.error('Recette introuvable')
                // this.$router.push({name: 'home'})
                return
            }
            this.recette = response.data.api
            this.recette.imgUrls = this.recette.images.map(function(obj) {return obj['url']})
        }
    },
    async mounted () {
        this.getRecette()
        // this.$eventHub.$on('print-recette', async (slug) => {
        //     await this.getRecette(slug)
        //     this.exportToPDF()
        // })
    },
}
</script>

<style>
</style>