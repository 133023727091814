<template>
    <div>
        <admin-template>
            <div class="bg-white rounded shadow p-6">
                <h1 class="text-2xl font-bold mb-4">Configuration</h1>

                <template v-if="config">
                    <div class="grid grid-cols-2 gap-4">
                        <div class="bg-slate-100 rounded p-4">
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Lien page Facebook</label>
                                <input v-model="config.fb_lien" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Lien page Instagram</label>
                                <input v-model="config.insta_lien" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Lien page Pinterest</label>
                                <input v-model="config.pinterest_lien" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Lien page TikTok</label>
                                <input v-model="config.tiktok_lien" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Lien page Thread</label>
                                <input v-model="config.thread_lien" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
                        </div>
                        <div>
                            <div class="bg-slate-100 rounded p-4">
                                <div class="mb-4">
                                    <label class="text-sm font-semibold">Logo header</label>
                                    <input v-model="config.logo_header" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                                </div>
                                <div class="mb-4">
                                    <label class="text-sm font-semibold">Logo footer</label>
                                    <input v-model="config.logo_footer" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                                </div>
                            </div>
                            <div class="bg-slate-100 rounded p-4 mt-4">
                                <div class="mb-4">
                                    <label class="text-sm font-semibold">Image conseillière</label>
                                    <input v-model="config.image_presentation" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                                </div>
                                <div class="mb-4">
                                    <label class="text-sm font-semibold">Titre conseillière</label>
                                    <input v-model="config.titre_conseilliere" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                                </div>
                                <div class="mb-4">
                                    <label class="text-sm font-semibold">Présentation conseillière</label>
                                    <input v-model="config.presentation" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div class="bg-slate-100 rounded p-4 mt-4">
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Image atelier 1</label>
                                <input v-model="config.image_atelier" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
        
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Image atelier 2</label>
                                <input v-model="config.image_atelier_2" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
                        </div>
                        <div class="bg-slate-100 rounded p-4 mt-4">
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Image fond newsletter</label>
                                <input v-model="config.image_newsletter" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
                        </div>
                    </div>

                    <div class="text-center">
                        <button @click="saveForm" class="mt-4 px-4 py-2 text-white bg-green-500 hover:bg-green-600 duration-200 cursor-pointer rounded">Enregistrer</button>
                    </div>
                </template>
            </div>
        </admin-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            config: null
        }
    },
    methods: {
        async getConfig() {
            let response = await this.$request.post('/config/get')
            this.config = response.data.api
        },

        async saveForm() {
            await this.$request.post('/config/set', this.config)
            this.$toast.success('Paramètres modifiés')
        }
    },
    mounted () {
        this.getConfig();
    },
}
</script>

<style lang="scss" scoped>

</style>