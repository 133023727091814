<template>
    <div>
        <modal name="modal-edit-add-categ" height="auto" :scrollable="true">
            <div class="p-10" v-if="form">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        <template v-if="form.id">Modifier la catégorie</template>
                        <template v-if="!form.id">Ajouter une catégorie</template>
                    </h2>
                    <button @click="$modal.hide('modal-edit-add-categ')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <label class="text-sm font-semibold">Nom de la catégorie</label>
                    <input v-model="form.nom" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />

                    <div class="mt-4">
                        <div class="text-sm font-semibold">Sous catégorie</div>
                        <div v-for="(sousCateg, sousCategIndex) in form.sous_categories" :key="'sous-categ-' + sousCategIndex" class="flex items-center gap-2">
                            <input v-model="sousCateg.nom" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            <button @click="removeSousCateg(sousCategIndex)"><i class="fas fa-times text-red-500"></i></button>
                        </div>
                        <button @click="addSousCateg" class="mt-2 px-4 py-2 bg-gray-200 hover:bg-gray-300 duration-200 rounded text-sm"><i class="fas fa-plus mr-1"></i> Ajouter une sous catégorie</button>
                    </div>

                    <div class="border-t pt-4 mt-4 flex items-center justify-between gap-2">
                        <button @click="save" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-sm">Enregistrer <i class="fas fa-check ml-1"></i></button>
                        <button @click="$modal.show('modal-confirm-delete')" class="px-4 py-2 bg-red-500 hover:bg-red-600 duration-200 text-white rounded text-sm">Supprimer <i class="fas fa-trash ml-1"></i></button>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-confirm-delete" height="auto" :scrollable="true">
            <div class="p-10" v-if="form">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">Confirmer la suppression</h2>
                    <button @click="$modal.hide('modal-confirm-delete')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="p-4 bg-slate-100 rounded">
                        Souhaitez-vous réellement supprimer la catégorie et les sous catégories ?
                    </div>
                    <button @click="deleteCateg" class="mt-2 px-4 py-2 bg-red-500 hover:bg-red-600 duration-200 text-white rounded">Supprimer <i class="fas fa-trash ml-1"></i></button>
                </div>
            </div>
        </modal>
        <admin-template>
            <div class="bg-white rounded shadow p-6">
                <h1 class="text-2xl font-bold mb-4">Catégories</h1>

                <button @click="initForm(); $modal.show('modal-edit-add-categ')" class="px-4 py-2 rounded text-sm bg-gray-100 hover:bg-gray-200 duration-200 mb-4">Ajouter une catégorie</button>

                <div>
                    <div @click="displayModalEdit(categorie)" v-for="(categorie, categorieIndex) in categories" :key="'categorie-item-' + categorieIndex" class="border p-4 rounded duration-200 cursor-pointer mb-2 hover:bg-gray-50 duration-200">
                        <div class="font-bold text-lg mb-2 w-[25vw]">{{ categorie.nom }}</div>
                        <div class="pl-4 border-l-2">
                            <div v-for="(sousCateg, sousCategIndex) in categorie.sous_categories" :key="'sous-categ-item-' + sousCategIndex" class="">
                                {{ sousCateg.nom }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </admin-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            categories: [],
            form: null
        }
    },

    methods: {
        initForm() {
            this.form = {
                nom: null,
                sous_categories: [
                    {nom: null}
                ]
            }
        },
        async getCategories() {
            let response = await this.$request.get('/categorie/list')
            this.categories = response.data.api
        },

        addSousCateg() {
            this.form.sous_categories.push({
                nom: null
            })
        },

        removeSousCateg(index) {
            this.form.sous_categories.splice(index, 1)
        },

        async save() {
            let response = await this.$request.post('/categorie/edit-add', this.form)
            if (response.data.success == 1) {
                this.$toast.success(this.form.id ? 'Catégorie modifiée' : 'Catégorie ajoutée')
                this.initForm()
                this.getCategories()
                this.$modal.hide('modal-edit-add-categ')
            }
        },

        displayModalEdit(categ) {
            this.form = JSON.parse(JSON.stringify(categ))
            this.$modal.show('modal-edit-add-categ')
        },

        async deleteCateg() {
            await this.$request.post('/categories/delete', {
                id: this.form.id
            })
            this.$toast.success('Catégorie supprimée')
            this.initForm()
            this.getCategories()
            this.$modal.hide('modal-confirm-delete')
            this.$modal.hide('modal-edit-add-categ')
        }
    },

    mounted () {
        this.getCategories()
        this.initForm()
    },
}
</script>

<style lang="scss" scoped>

</style>