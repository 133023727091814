import axios from 'axios'

let account = localStorage.getItem('admin')

console.log(account);

const instance = axios.create({
    // baseURL: 'http://127.0.0.1:8015/api/',
    baseURL: 'https://nec-api.va-net.fr/api/',
    timeout: 20000,
    headers: {'auth-token': account ? JSON.parse(account).token : null}
});

export default instance