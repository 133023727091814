<template>
    <div>
        <admin-template>
            <div class="container mx-auto">
                <div class="text-center mt-4 mb-8">
                    <h1 class="font-black text-3xl py-2">Novice en cuisine</h1>
                    <h2 class="font-light text-lg">Blogueuse culinaire et conseillère Guy Demarle</h2>
                </div>

                <div class="grid grid-cols-3 gap-4">
                    <div class="bg-white rounded shadow text-center p-8">
                        <template v-if="stats">
                            <div class="font-bold text-2xl">{{ stats.nb_recettes }}</div>
                            <div class="font-light text-xl">Recettes</div>
                        </template>
                    </div>
                    <div class="bg-white rounded shadow text-center p-8">
                        <template v-if="stats">
                            <div class="font-bold text-2xl">{{stats.comments}}</div>
                            <div class="font-light text-xl">Commentaires</div>
                        </template>
                    </div>
                    <div class="bg-white rounded shadow text-center p-8">
                        <template v-if="stats">
                            <div class="font-bold text-2xl">{{ stats.comments_check }}</div>
                            <div class="font-light text-xl">Commentaires à valider</div>
                        </template>
                    </div>
                </div>
                <div class="bg-white rounded shadow p-8 mt-4">
                    <div class="text-xl font-bold mb-4"><i class="fas fa-comments mr-1"></i> Commentaires à valider</div>
                    <div v-if="commentaires.length <= 0" class="bg-gray-50 p-4 rounded">Aucun commentaire à valider</div>
                    <div v-for="(commentaire, commentaireIndex) in commentaires" :key="'commentaire-' + commentaireIndex" class="border mb-2 p-2 rounded">
                        <div class="flex items-center justify-between gap-2">
                            <div class="grow">
                                <div class="font-bold mb-2">{{ commentaire.commentaire }}</div>
                                <div class="text-xs">Recette: <a class="text-blue-500 hover:underline" target="_blank" :href="'/recette/' + commentaire.recette.url_slug">{{ commentaire.recette.nom }}</a></div>
                                <div class="text-xs font-light mt-1">{{ commentaire.created_at | momentDateTime }}</div>
                            </div>
                            <div class="flex items-center gap-2">
                                <button @click="validCommentaire(commentaire, 1)" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white text-sm rounded">Valider</button>
                                <button @click="validCommentaire(commentaire, 0)" class="px-4 py-2 bg-red-500 hover:bg-red-600 text-white text-sm rounded">Supprimer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </admin-template>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data() {
        return {
            commentaires: [],
            stats: null,
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async getCommentaires() {
            let response = await this.$request.post('/commentaire/waiting-list')
            this.commentaires = response.data.api
        },

        async validCommentaire(commentaire, valid) {
            await this.$request.post('/commentaire/valid', {
                id: commentaire.id,
                valid: valid
            })
            this.getCommentaires()
        },
        async getStats() {
            let response = await this.$request.post('/stats/home')
            this.stats = response.data.api
        },
    },
    mounted () {
        this.getStats()
        this.getCommentaires()
    },
}
</script>

<style lang="scss" scoped>

</style>