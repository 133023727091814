<template>
    <div>
        <div class="fixed top-0 left-0 bottom-0 w-[236px] bg-white text-left">
            <div class="flex items-center justify-start text-left gap-3 p-2 py-6">
                <img class="w-[30px] h-[30px] rounded-full" src="https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg" />
                <div>
                    <div>{{ $account.nom }}</div>
                    <div class="text-xs font-light">{{ $account.email }}</div>
                </div>
            </div>
            <div class="px-2">
                <div @click="$router.push({name: 'admin.home'})" class="py-3 px-4 text-sm rounded-lg cursor-pointer mb-2" :class="$route.name == 'admin.home' ? 'bg-selected font-bold' : 'hover:bg-gray-100 duration-200'">
                    <i class="fas fa-home mr-1"></i> Accueil
                </div>
                <div @click="$router.push({name: 'admin.recettes'})" class="py-3 px-4 text-sm rounded-lg cursor-pointer mb-2" :class="$route.name == 'admin.recettes' ? 'bg-selected font-bold' : 'hover:bg-gray-100 duration-200'">
                    <i class="fas fa-cookie mr-1"></i> Recettes
                </div>
                <div @click="$router.push({name: 'admin.top-recettes'})" class="py-3 px-4 text-sm rounded-lg cursor-pointer mb-2" :class="$route.name == 'admin.top-recettes' ? 'bg-selected font-bold' : 'hover:bg-gray-100 duration-200'">
                    <i class="fas fa-star mr-1"></i> A la une
                </div>
                <div @click="$router.push({name: 'admin.newsletter'})" class="py-3 px-4 text-sm rounded-lg cursor-pointer mb-2" :class="$route.name == 'admin.newsletter' ? 'bg-selected font-bold' : 'hover:bg-gray-100 duration-200'">
                    <i class="fas fa-envelope mr-1"></i> Newsletter
                </div>
                <div @click="$router.push({name: 'admin.categories'})" class="py-3 px-4 text-sm rounded-lg cursor-pointer mb-2" :class="$route.name == 'admin.categories' ? 'bg-selected font-bold' : 'hover:bg-gray-100 duration-200'">
                    <i class="fas fa-tag mr-1"></i> Catégories
                </div>
                <div @click="$router.push({name: 'admin.ustensiles'})" class="py-3 px-4 text-sm rounded-lg cursor-pointer mb-2" :class="$route.name == 'admin.ustensiles' ? 'bg-selected font-bold' : 'hover:bg-gray-100 duration-200'">
                    <i class="fas fa-blender mr-1"></i> Ustensiles
                </div>
                <div @click="$router.push({name: 'admin.config'})" class="py-3 px-4 text-sm rounded-lg cursor-pointer mb-2" :class="$route.name == 'admin.config' ? 'bg-selected font-bold' : 'hover:bg-gray-100 duration-200'">
                    <i class="fas fa-cog mr-1"></i> Configurations
                </div>
                <div @click="logout" class="py-3 px-4 text-sm rounded-lg cursor-pointer mb-2 hover:bg-red-100 hover:text-red-700 duration-200">
                    <i class="fa-solid fa-arrow-right-from-bracket mr-1"></i> Déconnexion
                </div>
            </div>
        </div>
        <div class="admin-content bg-gray-50 fixed top-0 left-[236px] right-0 bottom-0 overflow-y-auto p-4">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        logout() {
            localStorage.removeItem('admin')
            window.location = '/admin/login'
        }
    },
}
</script>

<style>
.bg-selected {
    background: #f9d7d5;
}
</style>