<template>
    <div>
        <app-template>
            <template #default="{config}">
                <div class="container mx-auto lg:w-5/6">
                    <div class="md:grid grid-cols-5 gap-10 items-center justify-between mt-10 md:mt-20">
                        <div class="col-span-3 md:p-10 rounded">
                            <img class="mx-auto rounded mb-10 md:hidden" style="height: 50vh" :src="config.image_atelier_2" alt="cooker home">
                            <h1 class="font-bold text-2xl md:text-5xl">Les ateliers de cuisine</h1>
                            <p class="mt-7 font-light md:text-2xl text-gray-500">
                                Vous souhaitez réaliser un atelier culinaire Guy Demarle ?<br />
                                Devenez hôte et recevez des cadeaux !<br /><br />
                                Passez de bons moments autours de recettes originales, apprenez des astuces pour vous faciliter la cuisine et recevez des produits gratuits.
                            </p>
                            <button @click="$router.push({name: 'commande'})" class="md:text-lg bg-default px-4 md:px-7 py-2 md:py-4 my-6 md:my-10 rounded-xl">Nouvelle commande <i class="fas fa-cart-shopping ml-2"></i></button>
                            <!-- <a href="{{route('view.contact.home')}}" class="mt-7 bg-default hover:bg-default-dark duration-200 py-2.5 font-light text-sm button-default text-lg">Prendre rendez-vous en ligne</a> -->
                        </div>
                        <div class="col-span-2">
                            <img class="mx-auto rounded hidden md:block" style="height: 50vh" :src="config.image_atelier_2" alt="cooker home">
                        </div>
                    </div>

                    <!-- Presentation -->
                    <div>
                        <h1 class="font-base text-xl md:text-3xl border-b pb-7 mt-10 md:mt-20">Présentation</h1>
                        <div class="mt-10 md:flex items-center">
                            <div class="h-32 w-32 rounded-full" :style="'background-image: url(' + config.image_presentation + ')'" style="background-size: cover; background-position: center center;"></div>
                            <div class="mt-5 md:mt-0 ml-5">
                                <h3 class="font-bold text-3xl">{{ config.titre_conseilliere }}</h3>
                                <h5 class="font-light text-xl">Blogueuse et conseillère Guy Demarle</h5>
                            </div>
                        </div>
                        <div class="md:grid grid-cols-4 gap-10 mt-10 border rounded px-4 md:p-10">
                            <div class="rounded py-5 md:py-0 md:text-center">
                                <span class="text-4xl font-bold text-default">7</span><span class="text-xl ml-1 text-default">ans</span>
                                <div class="font-light mt-1">Conseillère culinaire</div>
                            </div>
                            <div class="rounded py-5 md:py-0 md:text-center">
                                <span class="text-4xl font-bold text-default">11</span><span class="text-xl ml-1 text-default">ans</span>
                                <div class="font-light mt-1">Blogueuse</div>
                            </div>
                            <div class="rounded py-5 md:py-0 md:text-center">
                                <span class="text-4xl font-bold text-default">1000+</span>
                                <div class="font-light mt-1">Recettes</div>
                            </div>
                            <div class="rounded py-5 md:py-0 md:text-center">
                                <span class="text-4xl font-bold text-default">Manager</span>
                                <div class="font-light mt-1">Leader Guy Demarle</div>
                            </div>
                        </div>

                        <div class="md:grid grid-cols-2 gap-4 mt-10">
                            <p class="bg-default-extralight rounded p-10 pb-12 font-light text-xl text-gray-700 tracking-wider text-justify leading-10">
                                <span class="text-4xl font-extrabold">"</span>
                                <span v-html="config.presentation"></span>
                                <span class="text-4xl font-extrabold">"</span>
                            </p>
                            <div class="full rounded" :style="'background-image: url(' + config.image_atelier_3 + ')'" style="background-size: cover; background-position: center center;">
                        </div>
                    </div>

                    <!-- Déroulement -->
                    <h1 class="font-base text-xl md:text-3xl border-b pb-4 md:pb-7 mt-10 md:mt-20">Déroulement d'un atelier</h1>

                    <div class="mt-10 md:mt-16 md:grid grid-cols-5 gap-10 items-center justify-between">
                        <div class="col-span-3 md:pr-10">
                            <h1 class="font-bold text-xl md:text-3xl">1. Prise de rendez-vous</h1>
                            <p class="mt-4 md:mt-7 font-light md:text-2xl text-gray-500 text-justify">Vous pouvez prendre un rendez-vous en ligne, par email ou sur les réseaux sociaux.</p>
                        </div>
                        <div class="col-span-2">
                            <img class="mx-auto hidden md:block" style="height: 30vh" src="https://i.ibb.co/Vq2xdCM/undraw-Calendar-re-ki49.png" alt="reservation">
                        </div>
                    </div>

                    <div class="mt-10 md:mt-16 md:grid grid-cols-5 gap-10 items-center justify-between">
                        <div class="col-span-2">
                            <img class="mx-auto hidden md:block" style="height: 30vh" src="https://i.ibb.co/1MndMMx/undraw-sweet-home-dkhr.png" alt="domicile">
                        </div>
                        <div class="col-span-3 md:pr-10">
                            <h1 class="font-bold text-xl md:text-3xl">2. Atelier à domicile</h1>
                            <p class="mt-4 md:mt-7 font-light md:text-2xl text-gray-500 text-justify">Vous n'avez pas besoin de vous déplacer, les ateliers se font à votre domicile. Je me déplace chez vous avec tout mon matériel.</p>
                        </div>
                    </div>

                    <div class="mt-10 md:mt-16 md:grid grid-cols-5 gap-10 items-center justify-between">
                        <div class="col-span-3 md:pr-10">
                            <h1 class="font-bold text-xl md:text-3xl">3. Cuisinons !</h1>
                            <p class="mt-4 md:mt-7 font-light md:text-2xl text-gray-500 text-justify">Découvrons ensemble des recettes que nous avons séléctionné auparavant. Partage, découverte et apprentissage sont les mots clés pour un atelier réussi.</p>
                        </div>
                        <div class="col-span-2">
                            <img class="mx-auto hidden md:block" style="height: 30vh" src="https://i.ibb.co/x681GBh/undraw-Cooking-p7m1.png" alt="cook">
                        </div>
                    </div>

                    <div class="mt-10 md:mt-16 md:grid grid-cols-5 gap-10 items-center justify-between">
                        <div class="col-span-2">
                            <img class="mx-auto hidden md:block" style="height: 30vh" src="https://i.ibb.co/2FPgt40/undraw-Cookie-love-re-lsjh.png" alt="eat">
                        </div>
                        <div class="col-span-3 md:pr-10">
                            <h1 class="font-bold text-xl md:text-3xl">4. Dégustation / Fin d'atelier</h1>
                            <p class="mt-4 md:mt-7 font-light md:text-2xl text-gray-500 text-justify">
                                Lorsque tout est prêt, une séance de dégustation s'impose. Il ne reste plus qu'à goûter nos préparations.<br />
                                Suite à 
                            </p>
                        </div>
                    </div>

                    <!-- Price -->
                    <h1 class="font-base text-xl md:text-3xl border-b pb-4 md:pb-7 mt-10 md:mt-20">A savoir</h1>
                    <h3 class="font-bold text-xl md:text-2xl mt-4 md:mt-10">Les ateliers sont gratuits, à domicile et sans obligation d'achat.</h3>
                    <p>Découvrez Guy Demarle en atelier culinaire: Vous allez adorer !</p>
                    <!-- Rdv -->
                    <h1 class="font-base text-xl md:text-3xl border-b pb-4 md:pb-7 mt-10 md:mt-20">Prendre rendez-vous</h1>
                    
                    <div class="md:mt-10 mt-4 mb-20">
                        <div class="grid sm:grid-cols-2 gap-10 md:border rounded-3xl">
                            <div class="border-b sm:border-b-0 sm:border-r text-center py-16">
                                <i class="fa-solid fa-at text-4xl"></i>
                                <div class="font-bold my-2">Par email</div>
                                <div class="font-light">marionnette01@hotmail.com</div>
                            </div>
                            <div class="text-center py-16">
                                <i class="fa-solid fa-globe text-4xl"></i>
                                <div class="font-bold my-2">Depuis le formulaire en ligne</div>
                                <!-- <a href="{{route('view.contact.home')}}" class="font-light underline cursor-pointer">Accéder au formulaire</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-template>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>