<template>
    <div>
        <modal name="modal-edit-add-recette" height="auto" width="80%" :scrollable="true">
            <div class="p-10" v-if="form">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        <template v-if="form.id">Modifier la recette</template>
                        <template v-if="!form.id">Ajouter une recette</template>
                    </h2>
                    <button @click="$modal.hide('modal-edit-add-recette')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="grid grid-cols-6 gap-10">
                        <div class="col-span-2">
                            <div class="mb-4 flex items-center">
                                <input v-model="form.display" id="display" type="checkbox" class="p-2 px-4 rounded bg-white border focus:outline-none text-gray-800 text-sm" />
                                <label for="display" class="text-sm font-semibold ml-2">Recette en ligne</label>
                            </div>
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Date de publication</label>
                                <input v-model="form.programmed_at" type="datetime-local" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Sous catégories</label>
                                <multiselect
                                v-model="form.sous_categories"
                                class="mt-1"
                                :options="sousCategories"
                                :multiple="true"
                                :close-on-select="false"
                                label="label"
                                :max-height="200"
                                track-by="id"
                                />
                            </div>

                            <div class="mb-4">
                                <label class="text-sm font-semibold">Recettes similaires</label>
                                <multiselect
                                v-model="form.similaires"
                                @search-change="searchSimilaires"
                                class="mt-1"
                                :options="similaireList"
                                label="nom"
                                :multiple="true"
                                :max-height="200"
                                track-by="id"
                                />
                            </div>

                            <div class="mb-4">
                                <label class="text-sm font-semibold">Ustensiles</label>
                                <multiselect
                                v-model="form.ustensiles"
                                @search-change="searchUstensiles"
                                class="mt-1"
                                :options="ustensilesList"
                                label="nom"
                                :multiple="true"
                                :max-height="200"
                                track-by="id"
                                />
                            </div>

                            <div class="mb-4">
                                <label class="text-sm font-semibold">Images</label>
                                <div class="border-b pb-2 mt-1">
                                    <input-file @input="addImage" keyword="recettes" :only-send="true" />
                                </div>
                                <div v-for="(img, imgIndex) in form.images" :key="'img-recette-' + imgIndex" class="border-b py-2">
                                    <div class="flex items-center justify-between">
                                        <img class="rounded h-[60px] border" :src="img" />
                                        <div class="flex items-center gap-2">
                                            <a :href="img" target="_blank" class="px-4 py-2 text-xs bg-gray-100 hover:bg-gray-200 duration-200 rounded">Voir</a>
                                            <button @click="removeImg(imgIndex)" class="px-4 py-2 text-xs bg-red-100 hover:bg-red-200 duration-200 rounded">Supprimer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-4">
                            <div class="bg-gray-50 p-4 rounded border">
                                <div class="mb-4">
                                    <label class="text-sm font-semibold">Nom de la recette</label>
                                    <input v-model="form.nom" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                                </div>
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="mb-4">
                                        <label class="text-sm font-semibold">Temps préparation</label>
                                        <input v-model="form.temps_preparation" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                                    </div>
                                    <div class="mb-4">
                                        <label class="text-sm font-semibold">Temps cuisson</label>
                                        <input v-model="form.temps_cuisson" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                                    </div>
                                    <div class="mb-4">
                                        <label class="text-sm font-semibold">Quantité</label>
                                        <input v-model="form.qtt" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <label class="text-sm font-semibold">Description</label>
                                    <vue-editor class="bg-white" v-model="form.description" :editor-toolbar="customToolbar" />
                                    <!-- <textarea v-model="form.description" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" rows="5"></textarea> -->
                                </div>
                                <div class="mb-4">
                                    <label class="text-sm font-semibold">Déroulement</label>
                                    <vue-editor class="bg-white" v-model="form.deroulement" :editor-toolbar="customToolbar" />
                                </div>
                                <div class="mb-4">
                                    <label class="text-sm font-semibold">Ingrédients</label>
                                    <div v-for="(ingredient, ingredientIndex) in form.ingredients" :key="'ingredient-inp-' + ingredientIndex">
                                        <div class="flex items-center gap-2">
                                            <input v-model="form.ingredients[ingredientIndex]" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                                            <button @click="removeIngredient(ingredientIndex)"><i class="fas fa-times text-red-500"></i></button>
                                        </div>
                                    </div>
                                    <div>
                                        <button @click="addIngredient" class="px-4 py-2 rounded bg-gray-400 hover:bg-gray-500 duration-200 rounded text-xs mt-1"><i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-4">
                        <button @click="save" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded">Enregistrer <i class="fas fa-check ml-1"></i></button>
                    </div>
                </div>
            </div>
        </modal>
        <admin-template>
            <div class="bg-white rounded shadow p-6">
                <h1 class="text-2xl font-bold mb-4">Recettes</h1>

                <div class="relative overflow-x-auto">
                    <div class="flex items-center gap-2 justify-between mb-4">
                        <button @click="initForm(); $modal.show('modal-edit-add-recette')" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 rounded text-sm"><i class="fas fa-plus mr-1"></i> Nouvelle recette</button>
                        <input v-model="searchInp" class="border px-4 py-2 rounded text-xs w-[200px] focus:outline-none" placeholder="Rechercher.." />
                    </div>
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    En ligne
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Nom
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Catégorie
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Programmé le
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(recette, recetteIndex) in recettes" :key="'recette-line-' + recetteIndex" class="bg-white border-b hover:bg-gray-50 duration-200">
                                <td class="px-6 py-1.5 w-[200px]">
                                    <template v-if="recette.display">
                                        <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"><i class="fas fa-check-circle mr-0.5"></i> En ligne</span>
                                    </template>
                                    <template v-else-if="!recette.display && recette.programmed_at">
                                        <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"><i class="fas fa-hourglass-half mr-0.5"></i> Programmé</span>
                                    </template>
                                    <template v-else>
                                        <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"><i class="fas fa-pen mr-0.5"></i> En cours d'édition</span>
                                    </template>
                                </td>
                                <td class="px-6 py-1.5">{{ recette.nom }}</td>
                                <td class="px-6 py-1.5">
                                    <template v-if="recette.sous_categories.length <= 0"><b class="text-orange-400"><i class="fas fa-exclamation-triangle mr-1"></i> Aucune</b></template>
                                    <template v-for="sousCateg in recette.sous_categories">
                                        {{ sousCateg.nom }}
                                    </template>
                                </td>
                                <td class="px-6 py-1.5">{{ recette.programmed_at | momentDateTime }}</td>
                                <td class="px-6 py-1.5 flex gap-2">
                                    <a :href="'/recette/' + recette.url_slug" target="_blank" class="px-2.5 py-1.5 text-xs bg-gray-200 hover:bg-gray-300 duration-200 rounded"><i class="fas fa-eye"></i></a>
                                    <button @click="displayEditRecette(recette)" class="px-2.5 py-1.5 text-xs bg-blue-500 hover:bg-blue-600 duration-200 text-white rounded"><i class="fas fa-pen"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="flex items-center justify-end mt-4">
                        <div class="text-sm font-light">
                            {{ recettesRequest.total + (recettesRequest.total > 1 ? ' résultats' : ' résultat') }}
                        </div>
                        <t-pagination
                        :total-items="recettesRequest.total"
                        :per-page="recettesRequest.per_page"
                        v-model="currentPage"
                        />
                    </div>
                </div>
            </div>
        </admin-template>
    </div>
</template>

<script>
import moment from 'moment'
import RecettePdf from './Components/RecettePdf.vue'

export default {
    components: {
        RecettePdf,
    },
    watch: {
        async currentPage(value, oldValue) {
            let url = '/recette/list-admin'
            if (value > oldValue && this.recettesRequest.next_page_url) {
                url = this.recettesRequest.next_page_url
            }
            if (value < oldValue && this.recettesRequest.prev_page_url) {
                url = this.recettesRequest.prev_page_url
            }
            if (value == 1) {
                url = this.recettesRequest.first_page_url
            }
            if (value == Math.ceil(this.recettesRequest.total / this.recettesRequest.per_page)) {
                url = this.recettesRequest.last_page_url
            }
            await this.getList(url)
            const el = this.$el.querySelector(".admin-content");
            if (el) {
                el.scrollTo(0, 0)
            }
        },

        searchInp() {
            clearTimeout(this.timerSearchInp)
            this.timerSearchInp = setTimeout(() => {
                this.getList(this.recettesRequest.path + '?page=' + this.recettesRequest.current_page)
            }, 500)
        }
    },
    data() {
        return {
            form: null,
            recettes: [],
            recettesRequest: [],
            currentPage: 0,
            searchInp: null,
            timerSearchInp: null,
            sousCategories: [],
            ustensilesList: [],
            similaireList: [],
            customToolbar: [
                [{ 'font': [] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                ['clean'],
            ]
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        initForm() {
            this.form = {
                id: null,
                display: false,
                nom: null,
                description: null,
                temps_preparation: null,
                temps_cuisson: null,
                qtt: null,
                deroulement: null,
                ingredients: [],
                programmed_at: null,
                sous_categories: [],
                ustensiles: [],
                images: [],
                similaires: [],
            }
        },

        async getList(url) {
            let response = await this.$request.post(url, {
                search: this.searchInp
            })
            this.recettesRequest = response.data.api
            this.recettes = this.recettesRequest.data
            this.recettes.forEach((item) => {
                item.ingredients = JSON.parse(item.ingredients)
                item.sous_categories = item.sous_categories.map((sousCateg) => ({...sousCateg, label: sousCateg.categorie.nom + ' / ' + sousCateg.nom}))
            })
        },

        async getSousCategories() {
            let response = await this.$request.get('/categories/sous-categorie/list')
            this.sousCategories = response.data.api.map((item) => ({...item, label: item.categorie.nom + ' / ' + item.nom}))
        },

        async save() {
            let endObj = {...this.form}
            endObj.ingredients = JSON.stringify(endObj.ingredients)
            endObj.ustensiles = JSON.stringify(endObj.ustensiles)
            endObj.similaires = JSON.stringify(endObj.similaires)
            endObj.sous_categories = endObj.sous_categories.map(function(obj) {return obj['id']})
            let response = await this.$request.post('/recette/edit-add', endObj)
            if (response.data.success == 1) {
                this.$toast.success((endObj.id ? 'Recette modifiée' : 'Recette ajoutée'))
                await this.getList()
                this.initForm()
                this.$modal.hide('modal-edit-add-recette')
            }
        },

        displayEditRecette(recette) {
            this.form = {
                id: recette.id,
                display: recette.display,
                nom: recette.nom,
                description: recette.description,
                temps_preparation: recette.temps_preparation,
                temps_cuisson: recette.temps_cuisson,
                qtt: recette.qtt,
                deroulement: recette.deroulement,
                ingredients: recette.ingredients,
                programmed_at: recette.programmed_at,
                sous_categories: recette.sous_categories,
                images: recette.images.map(function(obj) {return obj['url']}),
                ustensiles: recette.ustensiles,
                similaires: recette.similaires,
            }
            this.$modal.show('modal-edit-add-recette')
        },

        addIngredient() {
            if (!this.form.ingredients) {
                this.form.ingredients = []
            }
            this.form.ingredients.push('')
        },

        removeIngredient(index) {
            this.form.ingredients.splice(index, 1)
        },

        addImage(data) {
            this.form.images.push(data.url)
        },

        removeImg(index) {
            this.form.images.splice(index, 1)
        },

        async searchUstensiles(search) {
            let response = await this.$request.post('/ustensile/search', {
                search: search
            })
            this.ustensilesList = response.data.api
        },

        async searchSimilaires(search) {
            let response = await this.$request.post('/recettes/search', {
                search: search
            })
            this.similaireList = response.data.api
        }
    },
    mounted () {
        this.initForm()
        this.getList('/recette/list-admin')
        this.getSousCategories()
    },
}
</script>

