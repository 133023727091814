<template>
    <div>
        <div class="grid grid-cols-2 h-screen items-center">
            <div class="h-screen" style="background-image: url('https://images.unsplash.com/photo-1506368249639-73a05d6f6488?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'); background-size: cover; background-position: center center;"></div>
            <div class="p-10">
                <h1 class="text-3xl font-bold">Espace administrateur</h1>
                <h2 class="text-xl font-light mt-1">Connexion</h2>

                <div class="mt-10">
                    <input v-model="email" type="email" class="mb-4 p-2 px-4 font-light rounded bg-white border w-full focus:outline-none text-gray-800" placeholder="Adresse email" />
                    <input v-model="password" type="password" class="mb-4 p-2 px-4 font-light rounded bg-white border w-full focus:outline-none text-gray-800" placeholder="Mot de passe" />
                    <button @click="connect" class="px-4 py-2 bg-green-500 hover:bg-green-600 rounded text-white">Se connecter <i class="fas fa-check ml-1"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: null,
            password: null,
        }
    },
    methods: {
        async connect() {
            if (!this.email || !this.password) {
                this.$toast.warning('Formulaire incomplet')
                return
            }

            let response = await this.$request.post('/auth/connect', {
                email: this.email,
                password: this.password
            })
            if (response.data.success == 1) {
                localStorage.setItem('admin', JSON.stringify(response.data.api))
                window.location = '/admin'
            } else {
                this.$toast.error('Identifiants incorrects')
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>