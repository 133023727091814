<template>
    <div>
        <app-template>
            <template #default="{config}">
                <!-- Recettes à la une -->
                <div class="mt-4 md:mt-0 container mx-auto lg:w-5/6">
                    <div class="font-semibold text-2xl flex items-center gap-4">
                        <i class="fa-solid fa-fire text-default text-xl md:text-3xl"></i> Recettes à la une
                    </div>
        
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-10 mt-4 md:mt-8">
                        <div v-for="(topRecette, topRecetteIndex) in topRecettes" :key="'top-recette-' + topRecetteIndex">
                            <recette-card class="border-b md:border-0 mb-4 md:mb-0" :recette="topRecette.recette" />
                        </div>
                    </div>
                </div>

                <!-- conseilliere -->
                <div class="md:mt-10 rounded-xl md:rounded-none bg-default-extralight py-10 px-10 md:px-0">
                    <div class="container mx-auto md:flex items-center gap-10 lg:w-5/6">
                        <img class="h-[150px] rounded-full mx-auto md:mx-0" :src="config.image_presentation" alt="Marion courbarie">
                        <div>
                            <i class="fa-solid fa-utensils text-default text-2xl hidden md:block"></i>
                            <div class="text-2xl font-base mt-8 md:mt-2">{{ config.titre_conseilliere }}</div>
                            <p class="font-light text-sm mt-2" v-html="config.presentation"></p>
                            <button @click="$router.push({name: 'contact'})" class="bg-default-dark px-4 py-2 my-4 font-light rounded">Me contacter</button>
                        </div>
                    </div>
                </div>

                <!-- Dernières recettes -->
                <div class="md:bg-gray-100 py-4 md:py-10">
                    <div class="container mx-auto lg:w-5/6">
                        <div class="flex items-center gap-4">
                            <i class="fa-solid fa-spoon text-default text-3xl"></i>
                            <div class="text-xl font-thin">Dernières recettes</div>
                        </div>
        
                        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-10 mt-4 md:mt-8">
                            <div v-for="(lastRecette, lastRecetteIndex) in lastRecettes" :key="'last-recette-' + lastRecetteIndex">
                                <recette-card class="border-b md:border-0 mb-4 md:mb-0" :recette="lastRecette" />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Newsletter -->
                <div class="w-full rounded-xl md:p-0 md:rounded-none" style="background-size: cover; background-position: center center;" :style="'background-image: url(' + config.image_newsletter + ')'">
                    <div class="w-full h-full py-28 px-5 rounded-xl md:px-0 md:rounded-none bg-black bg-opacity-70 text-white">
                        <div class="container mx-auto lg:w-5/6">
                            <div class="md:grid grid-cols-2 gap-4 items-center">
                                <div>
                                    <h2 class="text-3xl font-black">Rejoignez la newsletter</h2>
                                    <p class="font-light mt-2">En vous inscrivant à la newsletter vous acceptez de recevoir toutes les nouvelles recettes par email lors de leurs publications</p>
                                </div>
                                <newsletter-input class="mt-10 md:mt-0" />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Atelier -->
                <div class="pt-10 md:py-20">
                    <div class="container mx-auto lg:w-5/6">
                        <div class="flex items-center gap-4">
                            <i class="fa-solid fa-kitchen-set text-default text-3xl"></i>
                            <div class="text-xl font-thin">Ateliers Guy Demarle</div>
                        </div>
        
                        <div class="md:grid grid-cols-2 gap-14 mt-4 md:mt-10 items-center">
                            <div>
                                <h2 class="text-3xl font-bold">Participez à un atelier et découvrez les produits Guy Demarle</h2>
                                <div class="flex gap-4 mt-4">
                                    <div class="bg-gray-100 rounded text-sm px-3 py-1.5"><i class="fas fa-euro mr-1"></i> Gratuit</div>
                                    <div class="bg-gray-100 rounded text-sm px-3 py-1.5"><i class="fas fa-ruler mr-1"></i> Sur mesure</div>
                                    <div class="bg-gray-100 rounded text-sm px-3 py-1.5"><i class="fas fa-person-walking-luggage mr-1"></i> A domicile</div>
                                </div>
                                <div class="text-xl font-light mt-4">Trouvons une date qui vous convienne pour réaliser un atelier. Ensemble, nous cuisinons la recette qui vous conviendra. Seul(e) ou avec vos amis, découvrez les produits <a class="text-default hover:underline cursor-pointer" target="_blank" href="https://www.guydemarle.com/">Guy Demarle</a> qui vous correspondent le mieux !</div>
                                <div class="flex gap-2">
                                    <button @click="$router.push({name: 'contact'})" class="mt-5 bg-default px-4 py-2 font-light rounded">Prendre rendez-vous</button>
                                    <button @click="$router.push({name: 'atelier'})" class="mt-5 bg-gray-100 hover:bg-gray-200 duration-200 px-4 py-2 font-light rounded">En savoir plus</button>
                                </div>
                            </div>
                            <div class="md:relative mt-10 md:-mt-10">
                                <img class="w-full rounded md:absolute z-20" :src="config.image_atelier" alt="">
                                <div class="flex justify-end">
                                    <div class="hidden md:block w-full h-[350px] -mr-5 -mt-5 bg-default opacity-40 rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
import RecetteCard from '../components/RecetteCard.vue';

export default {
    components: {
        RecetteCard,
    },

    data() {
        return {
            topRecettes: [],
            lastRecettes: [],
        }
    },

    methods: {
        async getTopRecettes() {
            let response = await this.$request.post('/recettes/get-top')
            this.topRecettes = response.data.api
        },

        async getLastRecettes() {
            let response = await this.$request.post('/recettes/last')
            this.lastRecettes = response.data.api
        },
    },
    mounted () {
        this.getTopRecettes()
        this.getLastRecettes();
    },
}
</script>

<style lang="scss" scoped>

</style>