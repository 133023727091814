<template>
    <div style="color: #454545">
        <div v-if="displayMobileMenu" @click="displayMobileMenu = false" class="fixed z-20 bg-black bg-opacity-70 left-0 right-0 top-0 bottom-0">
            <div @click.stop="" class="bg-white rounded-r w-[70vw] fixed top-0 bottom-0 left-0 p-10">
                <div class="text-right">
                    <button @click="displayMobileMenu = false" class="text-3xl text-red-400"><i class="fas fa-times"></i></button>
                </div>
                <div class="mb-4 py-2 text-lg font-bold" @click="$router.push({name: 'home'}).catch(()=>{}); displayMobileMenu = false" :class="$route.name == 'home' ? 'font-bold text-default' : ''">Accueil</div>
                <div class="mb-4 py-2 text-lg font-bold" @click="$router.push({name: 'recettes'}).catch(()=>{}); displayMobileMenu = false"  :class="$route.name == 'recettes' || $route.name == 'recette' ? 'font-bold text-default' : ''">Recettes</div>
                <div class="mb-4 py-2 text-lg font-bold" @click="$router.push({name: 'atelier'}).catch(()=>{}); displayMobileMenu = false"  :class="$route.name == 'atelier' ? 'font-bold text-default' : ''">Guy Demarle</div>
                <div class="mb-4 py-2 text-lg font-bold" @click="$router.push({name: 'commande'}).catch(()=>{}); displayMobileMenu = false"  :class="$route.name == 'commande' ? 'font-bold text-default' : ''">Nouvelle commande</div>
                <div class="mb-4 py-2 text-lg font-bold" @click="$router.push({name: 'contact'}).catch(()=>{}); displayMobileMenu = false"  :class="$route.name == 'contact' ? 'font-bold text-default' : ''">Contact</div>
            </div>
        </div>
        <div @click="displaySearch = false" v-if="displaySearch" class="fixed top-0 left-0 right-0 bottom-0 z-20 bg-black bg-opacity-60 flex items-center justify-center overflow-y-auto py-10">
            <div @click.stop="" class="bg-white p-6 rounded w-[40vw]">
                <div class="flex justify-end">
                    <button class="mb-2 text-red-300 hover:text-red-500 duration-200 flex items-center gap-2" @click="displaySearch = false">Fermer<i class="fas fa-times text-xl mt-0.5"></i></button>
                </div>
                <input @input="sendSearchRecette" v-model="searchRecette" type="text" class="border px-4 py-2 rounded w-full focus:outline-none font-light" :class="recettes.length <= 0 ? 'mb-4' : ''" placeholder="Rechercher une recette..">
                <div v-if="loadingSearchRecette" class="p-2 py-3">
                    <spinner :size="10" />
                </div>
                <template v-if="!loadingSearchRecette && recettes.length > 0">
                    <div v-for="(recette, recetteIndex) in recettes" :key="'recette-' + recetteIndex">
                        <a :href="'/recette/' + recette.url_slug">
                            <div class="p-3 border-b hover:bg-gray-100 duration-200 cursor-pointer">
                                <div class="flex items-center gap-2">
                                    <img class="w-[50px]" :src="recette.images[0].url" alt="">
                                    <div>
                                        <div class="font-bold">{{ recette.nom }}</div>
                                        <div class="text-sm text-default"><span v-for="sousCategorie in recette.sous_categories" :key="'search-sous-categ-' + sousCategorie.id">{{ sousCategorie.nom }}</span></div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </template>
            </div>
        </div>
        <div class="hidden md:block">
            <div class="py-4">
                <div class="container mx-auto lg:w-5/6 flex items-center justify-between gap-4">
                    <div class="flex items-center gap-4 text-2xl text-default">
                        <a target="_blank" :href="config ? config.fb_lien : null"><i class="fa-brands fa-facebook"></i></a>
                        <a target="_blank" :href="config ? config.insta_lien : null"><i class="fa-brands fa-instagram"></i></a>
                        <a target="_blank" :href="config ? config.pinterest_lien : null"><i class="fa-brands fa-pinterest"></i></a>
                        <a target="_blank" :href="config ? config.tiktok_lien : null"><i class="fa-brands fa-tiktok"></i></a>
                        <a target="_blank" :href="config ? config.thread_lien : null"><i class="fa-brands fa-square-threads"></i></a>
                    </div>
                    <div class="text-center">
                        <h1 class="font-black text-3xl py-2">Novice en cuisine</h1>
                        <h2 class="font-light text-lg">Blogueuse culinaire et conseillère Guy Demarle</h2>
                    </div>
                    <!-- <img class="h-[110px]" src="https://i.ibb.co/gb2TvRn/Logo-novice-en-cuisine-V10.png" alt="novice en cuisine logo"> -->
                    <div>
                        <button @click="displaySearch = true"><i class="fas fa-search text-xl"></i></button>
                    </div>
                </div>
            </div>
            <div class="flex justify-center items-center gap-10 py-10 mb-10 border-b container mx-auto lg:w-5/6">
                <div @click="$router.push({name: 'home'}).catch(()=>{})" class="cursor-pointer" :class="$route.name == 'home' ? 'font-bold text-default' : 'font-light hover:font-bold duration-200'">Accueil</div>
                <div @click="$router.push({name: 'recettes'}).catch(()=>{})"  class="cursor-pointer" :class="$route.name == 'recettes' || $route.name == 'recette' ? 'font-bold text-default' : 'font-light hover:font-bold duration-200'">Recettes</div>
                <div @click="$router.push({name: 'atelier'}).catch(()=>{})"  class="cursor-pointer" :class="$route.name == 'atelier' ? 'font-bold text-default' : 'font-light hover:font-bold duration-200'">Guy Demarle</div>
                <div @click="$router.push({name: 'commande'}).catch(()=>{})"  class="cursor-pointer" :class="$route.name == 'commande' ? 'font-bold text-default' : 'font-light hover:font-bold duration-200'">Nouvelle commande</div>
                <div @click="$router.push({name: 'contact'}).catch(()=>{})"  class="cursor-pointer" :class="$route.name == 'contact' ? 'font-bold text-default' : 'font-light hover:font-bold duration-200'">Contact</div>
            </div>

            <div class="min-h-[50vh]">
                <slot :config="config"></slot>
            </div>

            <!-- Footer -->
            <div class="mt-20 bg-default-extralight py-10">
                <div class="container mx-auto flex items-center gap-10 lg:w-5/6">
                    <div class="grid grid-cols-2 items-center">
                        <div class="flex items-center gap-8">
                            <img class="h-[80px]" :src="config.logo_footer" alt="novice en cuisine logo">
                            <div>
                                <div class="text-lg font-bold">Marion Courbarie</div>
                                <div class="font-light text-sm mt-1">Conseillère Guy Demarle</div>
                                <div class="font-thin text-sm mt-1">marionnette01@hotmail.com</div>
                            </div>
                        </div>
                        <div>
                            <p class="text-xs">Toutes les publications de ce blog sont la propriété intellectuelle de Marion COURBARIE. Elle est l’auteure des textes, recettes et des photographies (sauf annotation de sa part), et ne sont pas libres de droit. Chaque recette et chaque photo qui lui sont associées ne peuvent être copiées et diffusées sans que la source du blog soit citée clairement. La diffusion des photos de la recette doit faire l’objet d’une demande au préalable auprès de l’auteur avant sa diffusion.</p>
                            <div class="font-light text-xs mt-2">Copyrights © 2024</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Small screen -->
        <div class="md:hidden container">
            <!-- header -->
            <div class="px-2 flex items-center justify-between gap-28 mt-6 mb-10">
                <div>
                    <h1 class="font-black text-2xl mb-0.5">Novice en cuisine</h1>
                    <h2 class="font-light text-sm">Blogueuse culinaire et conseillère Guy Demarle</h2>
                </div>
                <button class="text-3xl" @click="displayMobileMenu = true">
                    <i class="fas fa-bars"></i>
                </button>
            </div>

            <!-- slot -->
            <div class="px-2">
                <slot :config="config"></slot>
            </div>

            <!-- Footer -->
            <div class="mt-20 bg-default-extralight py-50 p-4">
                <div class="container mx-auto">
                    <div class="border-b pb-4 mb-4 border-gray-900">
                        <div class="text-lg font-bold">Marion Courbarie</div>
                        <div class="font-light text-sm mt-1">Conseillère Guy Demarle</div>
                        <div class="font-thin text-sm mt-1">marionnette01@hotmail.com</div>
                    </div>
                    <p class="text-xs">Toutes les publications de ce blog sont la propriété intellectuelle de Marion COURBARIE. Elle est l’auteure des textes, recettes et des photographies (sauf annotation de sa part), et ne sont pas libres de droit. Chaque recette et chaque photo qui lui sont associées ne peuvent être copiées et diffusées sans que la source du blog soit citée clairement. La diffusion des photos de la recette doit faire l’objet d’une demande au préalable auprès de l’auteur avant sa diffusion.</p>
                    <div class="font-light text-xs mt-2">Copyrights © 2024</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    metaInfo: {
        title: "Novice en cuisine - Conseillère Guy Demarle",
        meta: [
            {name: 'description', content: 'Blog culinaire, Conseillère Guy Demarle et Ateliers de cuisine'},
            {property: 'og:title', content: 'Novice en cuisine - Conseillère Guy Demarle'},
            {property: 'og:site_name', content: 'Novice en cuisine'},
            {property: 'og:type', content: 'website'},
            {property: 'og:url', content: 'https://www.mon-site.com/ma-page-special'},
            {property: 'og:image', content: 'https://i.ibb.co/gb2TvRn/Logo-novice-en-cuisine-V10.png'},
            // Souvent identique à votre méta-description, mais pas toujours.
            {property: 'og:description', content: 'Blog culinaire, Conseillère Guy Demarle et Ateliers de cuisine'},

            // Carte Twitter
            {name: 'twitter:card', content: 'résumé'},
            {name: 'twitter:site', content: 'https://www.mon-site.com/ma-page-special'},
            {name: 'twitter:title', content: 'Novice en cuisine - Conseillère Guy Demarle'},
            {name: 'twitter:description', content: 'Blog culinaire, Conseillère Guy Demarle et Ateliers de cuisine'},
            // Votre identifiant twitter, si vous en avez un.
            {name: 'twitter:creator', content: '@gekkode'},
            {name: 'twitter:image:src', content: 'https://i.ibb.co/gb2TvRn/Logo-novice-en-cuisine-V10.png'},

            // Google / Schema.org markup:
            {itemprop: 'name', content: 'Novice en cuisine - Conseillère Guy Demarle'},
            {itemprop: 'description', content: 'Blog culinaire, Conseillère Guy Demarle et Ateliers de cuisine'},
            {itemprop: 'image', content: 'https://i.ibb.co/gb2TvRn/Logo-novice-en-cuisine-V10.png'}
        ]
    },
    data() {
        return {
            searchRecette: null,
            recettes: [],
            timerSeach: null,
            displaySearch: false,
            loadingSearchRecette: false,
            displayMobileMenu: false,
            config: null,
        }
    },
    methods: {
        async sendSearchRecette() {
            clearTimeout(this.timerSeach)
            if (!this.searchRecette) {
                this.recettes = []
                return
            }
            this.loadingSearchRecette = true
            this.timerSeach = setTimeout(async () => {
                let response = await this.$request.post('/recettes/main-search', {
                    search: this.searchRecette
                })
                if (response.data.success) {
                    this.recettes = response.data.api
                }
                this.loadingSearchRecette = false
            }, 500);
        },

        async getConfig() {
            let response = await this.$request.post('config/get')
            this.config = response.data.api
        },
    },
    mounted () {
        this.getConfig()
    },
}
</script>

<style lang="scss" scoped>

</style>