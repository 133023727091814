<template>
    <div>
        <div class="grid grid-cols-6 items-center gap-4">
            <div>
                <button @click="prevImage" class="opacity-50 hover:opacity-100 duration-200">
                    <i class="fas fa-chevron-left"></i>
                </button>
            </div>
            <div class="col-span-4">
                <div class="rounded w-auto h-[250px] md:h-[500px] mx-auto" :style="'background-image: url(' + selectedUrl + ')'" style="background-size: cover; background-position: center center;" />
            </div>
            <div>
                <button @click="nextImage" class="opacity-50 hover:opacity-100 duration-200">
                    <i class="fas fa-chevron-right"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        urls: {required: true},
    },
    data() {
        return {
            selectedUrl: null
        }
    },
    methods: {
        prevImage() {
            let findIndex = this.urls.findIndex((item) => item == this.selectedUrl)
            if (findIndex <= 0) {
                this.selectedUrl = this.urls[this.urls.length - 1]
            } else {
                this.selectedUrl = this.urls[findIndex - 1]
            }
        },
        nextImage() {
            let findIndex = this.urls.findIndex((item) => item == this.selectedUrl)
            if (findIndex > this.urls.length - 2) {
                this.selectedUrl = this.urls[0]
            } else {
                this.selectedUrl = this.urls[findIndex + 1]
            }
        }
    },
    mounted () {
        console.log(this.urls[0]);
        this.selectedUrl = this.urls[0]
    },
}
</script>

<style lang="scss" scoped>

</style>