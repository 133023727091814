<template>
    <div>
        <modal name="modal-select-categ" width="100%" height="auto" :scrollable="true">
            <div class="p-4">
                <div class="text-2xl mb-4">Choisir une catégorie</div>
                <a href="/recettes" class="block py-1 font-bold cursor-pointer mb-2" :class="!$route.params.categ ? 'text-default' : ''">Toutes les recettes</a>
                <div v-for="(categ, categIndex) in categories" :key="'categ-' + categIndex" class="mb-4">
                    <div class="mb-2" :class="selectedSousCategorie && selectedSousCategorie.categorie_id == categ.id ? 'font-bold text-default' : ''">{{ categ.nom }}</div>
                    <a :href="'/recettes/' + sousCateg.url_slug" class="block border-l ml-1 pl-4 py-1 font-thin cursor-pointer" :class="selectedSousCategorie && selectedSousCategorie.id == sousCateg.id ? 'text-default' : 'opacity-60 hover:opacity-100 hover:underline duration-200'" v-for="(sousCateg, sousCategIndex) in categ.sous_categories" :key="'sous-categ-' + sousCategIndex">{{ sousCateg.nom }}</a>
                </div>
            </div>
        </modal>

        <app-template>
            <div class="container mx-auto lg:w-5/6">
                <div class="md:grid grid-cols-7 mt-2 gap-10">
                    <div class="col-span-2 hidden md:block">
                        <div>
                            <h2 class="text-xl font-bold mt-1 mb-4"><i class="fas fa-list mr-1 text-lg"></i> Catégories</h2>
                            <a href="/recettes" class="block py-1 font-bold cursor-pointer mb-2" :class="!$route.params.categ ? 'text-default' : ''">Toutes les recettes</a>
                            <div v-for="(categ, categIndex) in categories" :key="'categ-' + categIndex" class="mb-4">
                                <div class="mb-2" :class="selectedSousCategorie && selectedSousCategorie.categorie_id == categ.id ? 'font-bold text-default' : ''">{{ categ.nom }}</div>
                                <a :href="'/recettes/' + sousCateg.url_slug" class="block border-l ml-1 pl-4 py-1 font-thin cursor-pointer" :class="selectedSousCategorie && selectedSousCategorie.id == sousCateg.id ? 'text-default' : 'opacity-60 hover:opacity-100 hover:underline duration-200'" v-for="(sousCateg, sousCategIndex) in categ.sous_categories" :key="'sous-categ-' + sousCategIndex">{{ sousCateg.nom }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-5">
                        <div class="md:flex items-center justify-between gap-2 mb-6">
                            <h2 class="text-xl font-bold"><i class="fas fa-spoon mr-1"></i> Recettes</h2>
                            <div class="flex items-center gap-2">
                                <input v-model="searchText" @input="getRecettes" class="w-[350px] rounded p-2 font-light rounded-l bg-white border focus:outline-none text-gray-800 text-sm mt-2 md:mt-0" placeholder="Rechercher une recette.." />
                                <button @click="$modal.show('modal-select-categ')" class="px-4 py-2 bg-slate-100 rounded mt-2 md:hidden"><i class="fas fa-bars"></i></button>
                            </div>
                        </div>
                        <div v-if="loading" class="p-4">
                            <spinner />
                        </div>
                        <div v-if="!loading && recettes && recettes.length > 0" class="md:grid grid-cols-3 gap-6">
                            <div v-for="(recette, recetteIndex) in recettes" :key="'recette-' + recetteIndex">
                                <recette-card class="border-b md:border-0 mb-4 md:mb-0" :recette="recette" />
                            </div>
                        </div>
                        <div class="text-xl font-bold text-gray-500 text-center mt-4 border p-10 rounded-lg" v-if="recettes && recettes.length <= 0">
                            Aucune recette trouvée
                        </div>
                        <div v-if="recettesRequest" class="mt-6 flex items-center justify-center gap-2">
                            <a :href="$route.params.categ ? ('/recettes/' + $route.params.categ + '?page=' + (recettesRequest.current_page - 1)) : '/recettes?page=' + (recettesRequest.current_page - 1)" v-if="recettesRequest.prev_page_url" class="px-4 py-3 bg-gray-200 hover:bg-gray-300 duration-200 cursor-pointer rounded"><i class="fas fa-chevron-left mr-2"></i> Page précédente</a>
                            <a :href="$route.params.categ ? ('/recettes/' + $route.params.categ + '?page=' + (recettesRequest.current_page + 1)) : '/recettes?page=' + (recettesRequest.current_page + 1)" v-if="recettesRequest.next_page_url" class="px-4 py-3 bg-gray-200 hover:bg-gray-300 duration-200 cursor-pointer rounded">Page suivante <i class="fas fa-chevron-right ml-2"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import RecetteCard from '../components/RecetteCard.vue';
export default {
    components: {
        RecetteCard,
    },
    data() {
        return {
            categories: [],
            selectedSousCategorie: null,
            recettes: null,
            recettesRequest: null,
            searchText: null,
            timerSearch: null,
            loading: false,
        }
    },
    methods: {
        async getCategories() {
            let response = await this.$request.get('/categories/blog-list')
            this.categories = response.data.api

            if (this.$route.params.categ) {
                this.categories.forEach((categ) => {
                    let sousCateg = categ.sous_categories.find((sousCateg) => sousCateg.url_slug == this.$route.params.categ)
                    if (sousCateg) {
                        this.selectedSousCategorie = sousCateg
                    }
                })
            }
        },
        async getRecettes(url) {
            clearTimeout(this.timerSearch)
            window.scrollTo(0, 0)
            this.loading = true
            this.timerSearch = setTimeout(async () => {
                let endObj = {
                    search: this.searchText
                }
                if (this.selectedSousCategorie && this.selectedSousCategorie.id) {
                    endObj.categ_id = this.selectedSousCategorie.id
                }
                let response = await this.$request.post('/recettes/blog-list', {
                    page: this.$route.query.page ? this.$route.query.page : 0
                })
                this.recettesRequest = response.data.api
                this.recettes = this.recettesRequest.data
                this.loading = false
            }, 500);
        }
    },
    async mounted () {
        this.getCategories()
        this.getRecettes()
    },
}
</script>

<style lang="scss" scoped>

</style>