import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Recettes from '../views/Recettes.vue'
import Recette from '../views/Recette.vue'
import Atelier from '../views/Atelier.vue'
import Contact from '../views/Contact.vue'
import Commande from '../views/Commande.vue'
// import RecettePdf from './../views/Admin/Components/RecettePdf.vue'

import AdminLogin from '../views/Admin/Login'
import AdminHome from '../views/Admin/Home'
import AdminCategories from '../views/Admin/Categories'
import AdminRecettes from '../views/Admin/Recettes'
import AdminTopRecettes from '../views/Admin/AdminTopRecettes'
import AdminUstensiles from '../views/Admin/Ustensiles'
import AdminNewsletter from '../views/Admin/AdminNewsletter'
import AdminConfig from '../views/Admin/AdminConfig'

import PageNotFound from '../views/PageNotFound'

Vue.use(VueRouter)

const routes = [
    {path: '/', name: 'home', component: Home},
    {path: '/recettes', name: 'recettes', component: Recettes},
    {path: '/recettes/:categ', name: 'recettes.categ', component: Recettes},
    // {path: '/recette/pdf', name: 'recette.pdf', component: RecettePdf},
    {path: '/recette/:slug', name: 'recette', component: Recette},
    {path: '/atelier', name: 'atelier', component: Atelier},
    {path: '/commande', name: 'commande', component: Commande},
    {path: '/contact', name: 'contact', component: Contact},

    {path: '/admin', name: 'admin.home', component: AdminHome},
    {path: '/admin/login', name: 'admin.login', component: AdminLogin},
    {path: '/admin/categories', name: 'admin.categories', component: AdminCategories},
    {path: '/admin/recettes', name: 'admin.recettes', component: AdminRecettes},
    {path: '/admin/top-recettes', name: 'admin.top-recettes', component: AdminTopRecettes},
    {path: '/admin/ustensiles', name: 'admin.ustensiles', component: AdminUstensiles},
    {path: '/admin/newsletter', name: 'admin.newsletter', component: AdminNewsletter},
    {path: '/admin/config', name: 'admin.config', component: AdminConfig},

    { path: "*", component: PageNotFound }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // if (localStorage.getItem('display-recette-popup')) {
    //     this.displayRecette = true
    // }

    window.scrollTo(0, 0)

    // Validation auth admin
    if (to.name && to.name.split('.')[0] == 'admin' && to.name != 'admin.login') {
        if (localStorage.getItem('admin')) {
            next()
        } else {
            window.location = '/admin/login'
        }
    } else {
        next()
    }
})

export default router
