import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// Components
import AppTemplate from '@/components/AppTemplate.vue'
Vue.component('app-template', AppTemplate)

import AdminTemplate from './views/Admin/Components/AdminTemplate.vue'
Vue.component('admin-template', AdminTemplate)

import InputFile from './components/InputFile.vue'
Vue.component('input-file', InputFile)

import Spinner from './components/Spinner.vue'
Vue.component('spinner', Spinner)

import NewsletterInput from './components/NewsletterInput.vue'
Vue.component('newsletter-input', NewsletterInput)

// import Vue2Editor from "vue2-editor";
// Vue.use(Vue2Editor);

// import VueTailwind from 'vue-tailwind'
// import {TPagination} from 'vue-tailwind/dist/components';
// const components = {TPagination}
// Vue.use(VueTailwind, components)


// Packages

// Toastification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {});

// Modal
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
Vue.use(VModal)

// SEO
import Meta from 'vue-meta';
Vue.use(Meta);

// Multiselect
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

// Variables
import apiService from './services/api.service'
Vue.prototype.$request = apiService

import AccountTool from './tools/AccountTool.js'
Vue.prototype.$account = AccountTool

import EventHub from '@/EventHub';
Vue.prototype.$eventHub = EventHub

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
