<template>
    <div>
        <modal name="modal-edit-newsletter" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLine">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">Modifier la newsletter</h2>
                    <button @click="$modal.hide('modal-edit-newsletter')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="mb-4">
                        <label class="text-sm font-semibold">Date d'envoi</label>
                        <input :min="minDate" v-model="selectedLine.programmed_at" type="datetime-local" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                    </div>
                    <div class="mb-4">
                        <label class="text-sm font-semibold">Titre</label>
                        <input v-model="selectedLine.titre" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                    </div>
                    <div class="mb-4">
                        <label class="text-sm font-semibold">Sujet</label>
                        <input v-model="selectedLine.objet" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                    </div>
                    <div class="mb-4">
                        <label class="text-sm font-semibold">Contenu</label>
                        <vue-editor class="bg-white" v-model="selectedLine.content" :editor-toolbar="customToolbar" />
                    </div>
                    <button @click="editSave" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded">Enregistrer <i class="fas fa-check ml-1"></i></button>
                </div>
            </div>
        </modal>

        <modal name="modal-delete-newsletter" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLine">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">Supprimer la newsletter</h2>
                    <button @click="$modal.hide('modal-delete-newsletter')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="p-4 bg-slate-100 rounded">
                        Souhaitez-vous réellement supprimer <b>{{ selectedLine.titre }}</b> ?
                    </div>
                    <button @click="deleteMessage" class="mt-2 px-4 py-2 bg-red-500 hover:bg-red-600 duration-200 text-white rounded">Supprimer <i class="fas fa-trash ml-1"></i></button>
                </div>
            </div>
        </modal>

        <admin-template>
            <div class="bg-white rounded shadow p-6">
                <h1 class="text-2xl font-bold mb-4">Newsletter</h1>
                <div class="grid grid-cols-4 gap-4">
                    <div>
                        <div class="border roudned sticky top-5">
                            <div @click="selectedTab = 'new'" class="border-b p-4 py-2 hover:bg-slate-100 duration-200 cursor-pointer" :class="selectedTab == 'new' ? 'bg-slate-100 font-bold' : ''"><i class="fas fa-plus mr-0.5"></i> Nouvelle</div>
                            <div @click="selectedTab = 'calendar'" class="border-b p-4 py-2 hover:bg-slate-100 duration-200 cursor-pointer" :class="selectedTab == 'calendar' ? 'bg-slate-100 font-bold' : ''"><i class="fas fa-calendar mr-0.5"></i> Calendrier</div>
                            <div @click="selectedTab = 'members'" class="p-4 py-2 hover:bg-slate-100 duration-200 cursor-pointer" :class="selectedTab == 'members' ? 'bg-slate-100 font-bold' : ''"><i class="fas fa-users mr-0.5"></i> Membres</div>
                        </div>
                    </div>
                    <div class="col-span-3">
                        <template v-if="selectedTab == 'new'">
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Date d'envoi</label>
                                <input :min="minDate" v-model="form.programmed_at" type="datetime-local" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Titre</label>
                                <input v-model="form.titre" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Sujet</label>
                                <input v-model="form.objet" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Contenu</label>
                                <vue-editor class="bg-white" v-model="form.content" :editor-toolbar="customToolbar" />
                            </div>
                            <button @click="save" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded">Enregistrer <i class="fas fa-check ml-1"></i></button>
                        </template>

                        <template v-if="selectedTab == 'calendar'">
                            <input @input="getMessages" class="mb-2 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" type="month" v-model="monthSearch" />
                            <div class="bg-slate-100 p-4 rounded border text-sm" v-if="messages.length <= 0">Aucune newsletter</div>
                            <div class="border p-4 rounded mb-2 flex items-center gap-2 justify-between" v-for="(message, messageIndex) in messages" :key="'msg-' + messageIndex">
                                <div>
                                    <div class="font-bold">{{ message.titre }}</div>
                                    <div class="font-light text-sm">{{ message.objet }}</div>
                                    <div class="font-light text-xs">{{ message.programmed_at | momentDateTime }}</div>
                                </div>
                                <div class="flex gap-2">
                                    <button @click="selectedLine = message; $modal.show('modal-edit-newsletter')" v-if="!message.sended" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 rounded text-xs mt-2">Modifier <i class="fas fa-pen ml-1"></i></button>
                                    <button @click="selectedLine = message; $modal.show('modal-delete-newsletter')" v-if="!message.sended" class="px-4 py-2 bg-red-100 hover:bg-red-200 duration-200 rounded text-xs mt-2">Supprimer <i class="fas fa-trash ml-1"></i></button>
                                </div>
                            </div>
                        </template>

                        <template v-if="selectedTab == 'members'">
                            <div class="pb-4 mb-4 border-b text-lg font-bold" v-if="members"><i class="fas fa-users mr-1"></i> {{ members.length }} membres</div>
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 border">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Email
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Date inscription
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(member, memberIndex) in members" :key="'member-line-' + memberIndex" class="bg-white border-b hover:bg-gray-50 duration-200">
                                        <td class="px-6 py-1.5">
                                            {{ member.email }}
                                        </td>
                                        <td class="px-6 py-1.5">{{ member.created_at | momentDate }}</td>
                                        <td class="px-6 py-1.5 flex gap-2">
                                            <a :href="'https://noviceencuisine.com/newsletter/unsubscribe/' + member.token" target="_blank" class="px-2.5 py-1.5 text-xs bg-red-200 hover:bg-red-300 duration-200 rounded"><i class="fas fa-trash"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </div>
                </div>
            </div>
        </admin-template>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    watch: {
        selectedTab(value) {
            if (value == 'members') {
                this.getMembers()
            }
        }
    },
    data() {
        return {
            selectedTab: 'new',
            form: {},
            customToolbar: [
                [{ 'font': [] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                ['clean'],
            ],
            messages: [],
            monthSearch: null,
            selectedLine: null,
            minDate: null,
            members: []
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async save() {
            await this.$request.post('/newsletter/edit-add-message', this.form)
            this.$toast.success('Newsletter ajoutée')
            this.form = {}
            this.getMessages()
        },

        async editSave() {
            await this.$request.post('/newsletter/edit-add-message', this.selectedLine)
            this.$toast.success('Newsletter modifiée')
            this.selectedLine = {}
            this.$modal.hide('modal-edit-newsletter')
            this.getMessages()
        },

        async getMessages() {
            let response = await this.$request.post('/newsletter/get-messages', {
                month: this.monthSearch
            })
            this.messages = response.data.api
        },

        async deleteMessage() {
            await this.$request.post('/newsletter/delete-message', {
                id: this.selectedLine.id
            })
            await this.getMessages()
            this.$toast.success('Newsletter supprimée')
            this.$modal.hide('modal-delete-newsletter')
        },

        async getMembers() {
            let response = await this.$request.post('/newsletter/members-list')
            this.members = response.data.api
        }
    },
    mounted () {
        this.minDate = moment(new Date()).format('YYYY-MM-DD HH:mm')
        this.monthSearch = moment().format('YYYY-MM')
        this.getMessages()
    },
}
</script>

<style lang="scss" scoped>

</style>