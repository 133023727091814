<template>
    <div>
        <input
        type="file"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-1.5"
        :id="labelToId"
        :placeholder="placeholder"
        :accept="fileType ? fileType : 'image/*,application/pdf'"
        @change="upload"
        :disabled="disabled"
        ref="fileupload"
        >

        <spinner class="my-3" v-if="loadingUpload" :size="10" />

        <div v-if="fileUrl && !loadingUpload">
            <a class="text-xs hover:underline text-blue-500" :href="fileUrl" target="_blank">
                Consulter le fichier
            </a>
        </div>
    </div>
</template>

<script>
import Compressor from 'compressorjs';

export default {
    props: {
        label: {
            type: String,
        },
        classSrc: {
            type: String
        },
        placeholder: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        defaultData: {},
        id: {},
        fileType: {},
        keyword: {},
        onlySend: {default: false}
    },
    watch: {
        defaultData() {
            this.setDefaultData()
        }
    },
    data() {
        return {
            loadingUpload: false,
            fileUrl: null,
            fileData: null
        }
    },
    computed: {
        labelToId: function() {
            if (this.label) {
                /* eslint-disable */
                return this.label.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"").replace(/\s/g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
            }
            return this.id
        }
    },
    methods: {
        openInputFile() {
            document.getElementById(this.labelToId).click()
        },
        async uploadFile(fileInput) {
            if (fileInput) {
                var bodyFormData = new FormData();
                bodyFormData.append('file', fileInput);
                bodyFormData.append('key', this.keyword);

                this.fileUrl = null
                let response = await this.$request.post('/file/add', bodyFormData)
                this.fileData = response.data.api
                if (this.fileData) {
                    this.fileUrl = this.fileData.url
                    // Emit Parent
                    this.$emit('input', this.fileData)
                    if (this.onlySend) {
                        this.fileUrl = null
                        this.fileData = null
                        this.$refs.fileupload.value = null;
                    }
                }
            } else {
                this.fileUrl = null
            }
        },

        compressFile(file) {
            if (!file) {
                return;
            }

            new Compressor(file, {
                quality: 0.5,
                convertSize: 0,
                convertTypes: ['image/gif', 'image/jpeg', 'image/png', 'image/heic', 'image/webp'],

                success: async (result) => {
                    if (result.size < 5000000) {
                        await this.uploadFile(result)
                    } else {
                        this.$toast.error('Fichier trop volumineux')
                    }
                    this.loadingUpload = false
                },
                error(err) {
                    console.warn('Error image compression:');
                    console.log(err.message);
                    return file
                },
            });
        },

        async upload(event) {
            // Loader
            this.loadingUpload = true

            // Set data
            let fileInput = event.target.files.length > 0 ? event.target.files[0] : null

            // Compress image
            if (fileInput) {
                let isFileImage = (file) => {
                    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/heic', 'image/webp'];
                    return file && acceptedImageTypes.includes(file['type'])
                }
                if (isFileImage(fileInput)) {
                    this.compressFile(fileInput)
                    return
                }
            }

            // Api
            await this.uploadFile(fileInput)

            // Loader
            this.loadingUpload = false
        },

        removeFile() {
            this.fileUrl = null
            this.fileData = null
            this.$emit('input', null)
        },

        setDefaultData() {
            if (this.defaultData) {
                this.fileData = this.defaultData
                this.fileUrl = this.defaultData.url
            }
        }
    },
    mounted() {
        this.setDefaultData()
    }
}
</script>
