<template>
    <div>
        <app-template>
            <template #default="{config}">
                <div class="container mx-auto lg:w-5/6">
                    <div class="md:grid grid-cols-2 gap-6">
                        <div class="mb-4 md:mb-0">
                            <div class="sticky top-5 bg-default-extralight p-10 rounded-xl">
                                <img class="h-[150px] rounded-full" :src="config.image_presentation" alt="Marion courbarie">
                                <div>
                                    <i class="fa-solid fa-utensils text-default text-2xl"></i>
                                    <div class="text-2xl font-base mt-2">{{ config.titre_conseilliere }}</div>
                                    <p class="font-thin text-xl mt-2">marionnette01@hotmail.fr</p>
                                </div>
                            </div>
                        </div>
                        <div class="bg-gray-50 border shadow p-10 rounded-xl">
                            <h1 class="text-3xl font-bold mb-4">Me contacter</h1>
                            <div class="mb-4">
                                <div class="grid grid-cols-2 gap-4">
                                    <div>
                                        <label class="text-sm font-semibold">Prénom</label>
                                        <input v-model="form.prenom" type="text" class="border mt-1 p-2 px-4 rounded bg-white w-full focus:outline-none text-gray-800 text-sm" />
                                    </div>
                                    <div>
                                        <label class="text-sm font-semibold">Nom</label>
                                        <input v-model="form.nom" type="text" class="border mt-1 p-2 px-4 rounded bg-white w-full focus:outline-none text-gray-800 text-sm" />
                                    </div>
                                </div>
                            </div>
        
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Email</label>
                                <input v-model="form.email" type="text" class="border mt-1 p-2 px-4 rounded bg-white w-full focus:outline-none text-gray-800 text-sm" />
                            </div>
        
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Sujet</label>
                                <select v-model="form.sujet" class="border mt-1 p-2 px-4 rounded bg-white w-full focus:outline-none text-gray-800 text-sm">
                                    <option>Commande Guy Demarle</option>
                                    <option>Atelier</option>
                                    <option>Recettes</option>
                                    <option>Autre</option>
                                </select>
                            </div>
        
                            <div class="mb-4">
                                <label class="text-sm font-semibold">Message</label>
                                <textarea v-model="form.message" type="text" rows="5" class="border mt-1 p-2 px-4 rounded bg-white w-full focus:outline-none text-gray-800 text-sm"></textarea>
                            </div>

                            <vue-recaptcha
                            class="mb-4"
                            @verify="validCaptcha"
                            sitekey="6LfnOT0jAAAAAI2TyHZZfuFBDUSKkh7enOnKrizn"
                            ></vue-recaptcha>

                            <button @click="sendContact" class="px-4 py-2 bg-gray-800 text-white rounded-lg font-bold">Envoyer <i class="fas fa-check ml-1"></i></button>
                        </div>
                    </div>
                </div>

                <!-- Newsletter -->
                <div class="w-full rounded-xl md:p-0 md:rounded-none mt-6" style="background-size: cover; background-position: center center; background-image: url('https://images.unsplash.com/photo-1615887381517-43e18edcd3ed?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')">
                    <div class="w-full h-full py-28 px-5 rounded-xl md:px-0 md:rounded-none bg-black bg-opacity-70 text-white">
                        <div class="container mx-auto lg:w-5/6">
                            <div class="md:grid grid-cols-2 gap-4 items-center">
                                <div>
                                    <h2 class="text-3xl font-black">Rejoignez la newsletter</h2>
                                    <p class="font-light mt-2">En vous inscrivant à la newsletter vous acceptez de recevoir toutes les nouvelles recettes par email lors de leurs publications</p>
                                </div>
                                <newsletter-input class="mt-10 md:mt-0" />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Atelier -->
                <div class="pt-10 md:py-20">
                    <div class="container mx-auto lg:w-5/6">
                        <div class="flex items-center gap-4">
                            <i class="fa-solid fa-kitchen-set text-default text-3xl"></i>
                            <div class="text-xl font-thin">Ateliers Guy Demarle</div>
                        </div>
        
                        <div class="md:grid grid-cols-2 gap-14 mt-4 md:mt-10 items-center">
                            <div>
                                <h2 class="text-3xl font-bold">Participez à un atelier et découvrez les produits Guy Demarle</h2>
                                <div class="flex gap-4 mt-4">
                                    <div class="bg-gray-100 rounded text-sm px-3 py-1.5"><i class="fas fa-euro mr-1"></i> Gratuit</div>
                                    <div class="bg-gray-100 rounded text-sm px-3 py-1.5"><i class="fas fa-ruler mr-1"></i> Sur mesure</div>
                                    <div class="bg-gray-100 rounded text-sm px-3 py-1.5"><i class="fas fa-person-walking-luggage mr-1"></i> A domicile</div>
                                </div>
                                <div class="text-xl font-light mt-4">Trouvons une date qui vous convienne pour réaliser un atelier. Ensemble, nous cuisinons la recette qui vous conviendra. Seul(e) ou avec vos amis, découvrez les produits <a class="text-default hover:underline cursor-pointer" target="_blank" href="https://www.guydemarle.com/">Guy Demarle</a> qui vous correspondent le mieux !</div>
                                <div class="flex gap-2">
                                    <button @click="$router.push({name: 'contact'})" class="mt-5 bg-default px-4 py-2 font-light rounded">Prendre rendez-vous</button>
                                    <button @click="$router.push({name: 'atelier'})" class="mt-5 bg-gray-100 hover:bg-gray-200 duration-200 px-4 py-2 font-light rounded">En savoir plus</button>
                                </div>
                            </div>
                            <div class="md:relative mt-10 md:-mt-10">
                                <img class="w-full rounded md:absolute z-20" src="https://chefsquare.fr/media/wysiwyg/EVJF.jpg" alt="">
                                <div class="flex justify-end">
                                    <div class="hidden md:block w-full h-[350px] -mr-5 -mt-5 bg-default opacity-40 rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    components: {
        VueRecaptcha,
    },
    data() {
        return {
            form: {},
            captchaIsValid: false,
        }
    },
    methods: {
        async sendContact() {
            if (!this.form.nom || !this.form.prenom || !this.form.email || !this.form.sujet || !this.form.message) {
                this.$toast.error('Formulaire incomplet')
                return
            }
            const validateEmail = (email) => {
                return String(email)
                    .toLowerCase()
                    .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
            };
            if (!validateEmail(this.form.email)) {
                this.$toast.error('Adresse email invalide')
                return
            }
            if (!this.captchaIsValid) {
                this.$toast.error('Merci de valider le captcha')
                return
            }
            this.form.captcha = 1
            let response = await this.$request.post('/contact/send-message', this.form)
            if (response.data.success) {
                this.form = {}
                this.$toast.success('Message envoyé')
            }
        },
        validCaptcha() {
            this.captchaIsValid = true
        }
    },
    mounted () {
        this.$forceUpdate()
    },
}
</script>

<style lang="scss" scoped>

</style>