<template>
    <div>
        <div class="flex items-center">
            <input v-model="newsletterEmail" class="p-2 px-4 font-light rounded-l bg-white border w-full focus:outline-none text-gray-800" placeholder="Adresse email" />
            <button @click="saveNewsLetter" class="px-4 py-2 bg-green-500 border border-green-500 rounded-r text-white">S'inscrire</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            newsletterEmail: null,
        }
    },
    methods: {
        async saveNewsLetter() {
            if (!this.newsletterEmail) {
                this.$toast.warning("L'adresse email est obligatoire")
                return
            }

            let response = await this.$request.post('/newsletter/add-email', {
                email: this.newsletterEmail
            })
            if (!response.data.success) {
                this.$toast.error(response.data.message)
            } else {
                this.newsletterEmail = null
                this.$toast.success('Vous avez rejoint la newsletter')
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>