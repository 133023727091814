<template>
    <div>
        <modal name="modal-confirm-delete" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLine">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">Confirmer la suppression</h2>
                    <button @click="$modal.hide('modal-confirm-delete')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="bg-gray-50 p-4 rounded">
                        Souhaitez-vous réellement supprimer <b>{{ selectedLine.nom }}</b> ?
                    </div>
                    <button class="px-4 py-2 bg-red-500 hover:bg-red-600 duration-200 text-white rounded text-sm" @click="deleteUstensile">Confirmer</button>
                </div>
            </div>
        </modal>
        <modal name="modal-edit-add-ustensile" height="auto" :scrollable="true">
            <div class="p-10" v-if="form">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        <template v-if="form.id">Modifier l'ustensile</template>
                        <template v-if="!form.id">Ajouter un ustensile</template>
                    </h2>
                    <button @click="$modal.hide('modal-edit-add-ustensile')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="mb-4">
                        <label class="text-sm font-semibold">Nom</label>
                        <input v-model="form.nom" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                    </div>
                    <div class="mb-4">
                        <label class="text-sm font-semibold">Image</label>
                        <input-file @input="(data) => {if (data) { form.image = data.url }}" type="text" />
                    </div>
                    <div class="mb-4">
                        <label class="text-sm font-semibold">Prix</label>
                        <div class="text-xs">Ne pas mettre le signe euro</div>
                        <input v-model="form.prix" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                    </div>
                    <div class="mb-4">
                        <label class="text-sm font-semibold">Url Site Guy Demarle</label>
                        <input v-model="form.url" type="text" class="mt-1 p-2 px-4 rounded bg-white border w-full focus:outline-none text-gray-800 text-sm" />
                    </div>

                    <button @click="save" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded">Enregistrer <i class="fas fa-check ml-1"></i></button>
                </div>
            </div>
        </modal>
        <admin-template>
            <div class="bg-white rounded shadow p-6">
                <h1 class="text-2xl font-bold mb-4">Ustensiles</h1>

                <button @click="initForm(); $modal.show('modal-edit-add-ustensile')" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 rounded text-sm mb-4"><i class="fas fa-plus mr-1"></i> Ajouter un ustensile</button>

                <div class="relative overflow-x-auto">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Nom
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Prix
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Url
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(ustensile, ustensileIndex) in ustensiles" :key="'ustensile-line-' + ustensileIndex" class="bg-white border-b hover:bg-gray-50 duration-200">
                                <td class="px-6 py-2">{{ ustensile.nom }}</td>
                                <td class="px-6 py-2"><img :src="ustensile.image" class="h-[50px]" /></td>
                                <td class="px-6 py-2">{{ ustensile.prix || '-' }}</td>
                                <td class="px-6 py-2">
                                    <a v-if="ustensile.url" class="text-blue-500 hover:underline" target="_blank" :href="ustensile.url"><i class="fas fa-link mr-1"></i> Consulter</a>
                                    <template v-else>-</template>
                                </td>
                                <td class="px-6 py-2 flex items-center gap-2">
                                    <button @click="displayEditUstensile(ustensile)" class="mt-2.5 px-2.5 py-1.5 text-xs bg-blue-500 hover:bg-blue-600 duration-200 text-white rounded">Modifier <i class="fas fa-pen ml-0.5"></i></button>
                                    <button @click="selectedLine = ustensile; $modal.show('modal-confirm-delete')" class="mt-2.5 px-2.5 py-1.5 text-xs bg-red-500 hover:bg-red-600 duration-200 text-white rounded">Supprimer <i class="fas fa-trash ml-0.5"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </admin-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {},
            selectedLine: null,
            ustensiles: []
        }
    },
    methods: {
        initForm() {
            this.form = {
                nom: null,
                image: null,
                prix: null,
                url: null
            }
        },

        displayEditUstensile(ustensile) {
            this.form = {
                id: ustensile.id,
                nom: ustensile.nom,
                image: ustensile.image,
                prix: ustensile.prix,
                url: ustensile.url
            }
            this.$modal.show('modal-edit-add-ustensile')
        },

        async getList() {
            let response = await this.$request.post('ustensile/list')
            this.ustensiles = response.data.api
        },

        async save() {
            let endObj = {...this.form}
            let response = await this.$request.post('/ustensile/edit-add', endObj)
            if (response.data.success == 1) {
                this.$toast.success((endObj.id ? 'Ustensile modifié' : 'Ustensile ajouté'))
                await this.getList()
                this.initForm()
                this.$modal.hide('modal-edit-add-ustensile')
            }
        },

        async deleteUstensile() {
            let response = await this.$request.post('/ustensile/delete', {
                id: this.selectedLine.id
            })
            if (response.data.success == 1) {
                this.$modal.hide('modal-confirm-delete')
                this.selectedLine = null
                this.$toast.success('Ustensile supprimé')
                this.getList()
            }
        }
    },
    mounted () {
        this.getList()
    },
}
</script>

<style lang="scss" scoped>

</style>