<template>
    <div>
        <app-template>
            <template #default="{config}">
                <div class="container mx-auto lg:w-5/6">
                    <div class="md:grid grid-cols-3 gap-4">
                        <div class="col-span-2 bg-gray-50 mt-6 md:mt-0 md:p-10 rounded-xl">
                            <div class="flex items-center gap-4">
                                <div>
                                    <h2 class="text-2xl font-bold text-slate-800">Profitez de 5€ de remise</h2>
                                    <div>Sur votre première commande grâce au parrainage</div>
                                </div>
                            </div>
                            <div class="mt-6">
                                <ol class="relative border-s border-gray-200">                  
                                    <li class="mb-10 ms-4">
                                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                                        <h3 class="text-lg font-semibold text-gray-900">1. Accès à la boutique</h3>
                                        <p class="mb-4 text-base font-normal text-gray-500">Rendez-vous sur la boutique <a class="text-blue-500 hover:underline" target="_blank" href="https://www.guydemarle.com/">https://www.guydemarle.com</a></p>
                                    </li>
                                    <li class="mb-10 ms-4">
                                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                                        <h3 class="text-lg font-semibold text-gray-900">2. Création de votre compte</h3>
                                        <p class="mb-4 text-base font-normal text-gray-500">Rendez-vous sur l'onglet "Nouveau client"</p>
                                    </li>
                                    <li class="mb-10 ms-4">
                                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                                        <h3 class="text-lg font-semibold text-gray-900">3. Coordonnées</h3>
                                        <p class="mb-4 text-base font-normal text-gray-500">Rentrez vos coordonnées puis descendez</p>
                                    </li>
                                    <li class="mb-10 ms-4">
                                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                                        <h3 class="text-lg font-semibold text-gray-900">4. Conseillière</h3>
                                        <p class="mb-4 text-base font-normal text-gray-500">"Avez-vous une conseillère ?", notez "Courbarie Marion-01851"</p>
                                    </li>
                                    <li class="mb-10 ms-4">
                                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                                        <h3 class="text-lg font-semibold text-gray-900">5. Parrainage</h3>
                                        <p class="mb-4 text-base font-normal text-gray-500">Dans la rubrique parrain, notez le mail "marionnette01@hotmail.com"</p>
                                    </li>
                                    <li class="mb-10 ms-4">
                                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                                        <h3 class="text-lg font-semibold text-gray-900">6. Code promo</h3>
                                        <p class="mb-4 text-base font-normal text-gray-500">Copiez le code promo de 5€ dans l'onglet code promo du panier</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="mt-6 md:mt-0">
                            <div class="sticky top-5 bg-default-extralight p-10 rounded-xl">
                                <img class="h-[150px] rounded-full" :src="config.image_presentation" alt="Marion courbarie">
                                <div>
                                    <i class="fa-solid fa-utensils text-default text-2xl"></i>
                                    <div class="text-2xl font-base mt-2">{{ config.titre_conseilliere }}</div>
                                    <p class="font-light text-sm mt-2" v-html="config.presentation"></p>
                                    <button @click="$router.push({name: 'contact'})" class="bg-default-dark px-4 py-2 my-4 font-light rounded">Me contacter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Newsletter -->
                <div class="mt-6 w-full rounded-xl md:p-0 md:rounded-none" style="background-size: cover; background-position: center center; background-image: url('https://images.unsplash.com/photo-1615887381517-43e18edcd3ed?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')">
                    <div class="w-full h-full py-28 px-5 rounded-xl md:px-0 md:rounded-none bg-black bg-opacity-70 text-white">
                        <div class="container mx-auto lg:w-5/6">
                            <div class="md:grid grid-cols-2 gap-4 items-center">
                                <div>
                                    <h2 class="text-3xl font-black">Rejoignez la newsletter</h2>
                                    <p class="font-light mt-2">En vous inscrivant à la newsletter vous acceptez de recevoir toutes les nouvelles recettes par email lors de leurs publications</p>
                                </div>
                                <newsletter-input class="mt-10 md:mt-0" />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Atelier -->
                <div class="pt-10 md:py-20">
                    <div class="container mx-auto lg:w-5/6">
                        <div class="flex items-center gap-4">
                            <i class="fa-solid fa-kitchen-set text-default text-3xl"></i>
                            <div class="text-xl font-thin">Ateliers Guy Demarle</div>
                        </div>
        
                        <div class="md:grid grid-cols-2 gap-14 mt-4 md:mt-10 items-center">
                            <div>
                                <h2 class="text-3xl font-bold">Participez à un atelier et découvrez les produits Guy Demarle</h2>
                                <div class="flex gap-4 mt-4">
                                    <div class="bg-gray-100 rounded text-sm px-3 py-1.5"><i class="fas fa-euro mr-1"></i> Gratuit</div>
                                    <div class="bg-gray-100 rounded text-sm px-3 py-1.5"><i class="fas fa-ruler mr-1"></i> Sur mesure</div>
                                    <div class="bg-gray-100 rounded text-sm px-3 py-1.5"><i class="fas fa-person-walking-luggage mr-1"></i> A domicile</div>
                                </div>
                                <div class="text-xl font-light mt-4">Trouvons une date qui vous convienne pour réaliser un atelier. Ensemble, nous cuisinons la recette qui vous conviendra. Seul(e) ou avec vos amis, découvrez les produits <a class="text-default hover:underline cursor-pointer" target="_blank" href="https://www.guydemarle.com/">Guy Demarle</a> qui vous correspondent le mieux !</div>
                                <div class="flex gap-2">
                                    <button @click="$router.push({name: 'contact'})" class="mt-5 bg-default px-4 py-2 font-light rounded">Prendre rendez-vous</button>
                                    <button @click="$router.push({name: 'atelier'})" class="mt-5 bg-gray-100 hover:bg-gray-200 duration-200 px-4 py-2 font-light rounded">En savoir plus</button>
                                </div>
                            </div>
                            <div class="md:relative mt-10 md:-mt-10">
                                <img class="w-full rounded md:absolute z-20" src="https://chefsquare.fr/media/wysiwyg/EVJF.jpg" alt="">
                                <div class="flex justify-end">
                                    <div class="hidden md:block w-full h-[350px] -mr-5 -mt-5 bg-default opacity-40 rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </app-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>

</style>