<template>
    <div>
        <modal name="print-pdf" height="auto" width="793" :scrollable="true">
            <div v-if="recette" style="width: 21cm">
                <recette-pdf :slug="recette.url_slug" />
            </div> 
        </modal>

        <app-template>
            <template #default="{config}">
                <template v-if="recette">
                    <!-- Admin alert -->
                    <div v-if="isAdmin && !recette.display" class="fixed bottom-5 right-5 p-4 bg-orange-200 rounded-lg bg-opacity-50" style="z-index: 999">
                        <b>Mode démonstration</b>
                        <p class="mt-1 font-light text-xs">
                            Cette recette n'est pas en ligne.<br />
                            Vous pouvez uniquement la consulter en tant qu'administrateur
                        </p>
                    </div>

                    <!-- Modal welcome -->
                    <div v-if="!displayRecette" class="fixed top-0 left-0 right-0 bottom-0 bg-slate-900 bg-opacity-80 flex items-center justify-center">
                        <div class="bg-white p-10 rounded w-[50vw]">
                            <div class="flex items-center gap-8">
                                <img class="h-[100px] rounded-full" src="https://i.ibb.co/0GbntPG/profil.png" alt="Marion courbarie" loading="lazy">
                                <div>
                                    <div class="text-xl font-bold">Je suis conseillière Guy Demarle</div>
                                    <div>Découvrez nos produits Guy Demarle</div>
                                    <div class="flex items-center gap-2">
                                        <button @click="removePopUp" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded mt-4">Découvrir la recette</button>
                                        <button class="px-4 py-2 bg-slate-200 hover:bg-slate-300 duration-200 rounded mt-4">En savoir plus</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Recette -->
                    <div class="container mx-auto lg:w-5/6">
                        <template v-if="old">
                            <div class="md:grid grid-cols-5 gap-8 mb-10">
                                <div class="col-span-2 my-10 md:my-0">
                                    <div class="sticky top-10">
                                        <h1 class="text-3xl font-bold">{{ recette.nom }}</h1>
                                        <h4 class="mt-2 font-light">Recette publiée le {{ recette.created_at | momentDate }}</h4>
                                    </div>
                                </div>
                                <div class="old-recipe bg-gray-100 rounded border p-4 md:p-10 col-span-3">
                                    <div v-html="recette.old_html"></div>
                                </div>
                            </div>
                        </template>
                        <div class="new-recipe" v-if="!old">
                            <div class="md:grid grid-cols-8 gap-8 items-center">
                                <div class="col-span-4">
                                    <image-slider class="py-10 md:py-0" :urls="recette.imgUrls" />
                                </div>
                                <div class="col-span-4">
                                    <h1 class="text-4xl font-semibold">{{ recette.nom }}</h1>
                                    <p class="mt-10 font-light">
                                        <p v-html="recette.description"></p>
                                    </p>
                                    <div class="mt-10 md:grid grid-cols-3 gap-5 border-b pb-6">
                                        <div class="mb-4 md:mb-0 pb-4 md:pb-0 border-b md:border-0">
                                            <div class="flex md:block items-center gap-4 border-r text-center px-4">
                                                <i class="far fa-clock text-2xl mb-1.5"></i>
                                                <div class="text-left md:text-center">
                                                    <div class="font-semibold">Préparation</div>
                                                    <div class="font-light text-sm">{{ recette.temps_preparation }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-4 md:mb-0 pb-4 md:pb-0 border-b md:border-0">
                                            <div class="flex md:block items-center gap-4 border-r text-center px-4">
                                                <i class="fas fa-blender text-2xl mb-1.5"></i>
                                                <div class="text-left md:text-center">
                                                    <div class="font-semibold">Cuisson</div>
                                                    <div class="font-light text-sm">{{ recette.temps_cuisson }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-4 md:mb-0 pb-4 md:pb-0">
                                            <div class="flex md:block items-center gap-4 text-center px-4">
                                                <i class="fas fa-bars-progress text-2xl mb-1.5"></i>
                                                <div class="text-left md:text-center">
                                                    <div class="font-semibold">Quantité</div>
                                                    <div class="font-light text-sm">{{ recette.qtt }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-5 flex gap-4 justify-between">
                                        <div class="flex gap-1">
                                            <a target="_blank" :href="facebookUrl" class="text-sm px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 duration-200 rounded"><i class="fab fa-square-facebook mr-1"></i> Partager</a>
                                            <a target="_blank" :href="pinterestUrl" class="text-sm px-4 py-2 bg-red-500 text-white hover:bg-red-600 duration-200 rounded"><i class="fab fa-pinterest mr-1"></i> Partager</a>
                                        </div>
                                        <button @click="$modal.show('print-pdf')" class="hidden md:block text-sm px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 rounded"><i class="fas fa-print mr-1"></i> Imprimer</button>
                                    </div>
                                </div>
                            </div>
                            <!-- Large screen -->
                            <div class="hidden md:block mt-20">
                                <div class="font-semibold text-2xl border-b-2 pb-4 mb-4">Réalisation</div>
                                <div class="grid grid-cols-6 gap-8 mt-8">
                                    <div class="col-span-4">
                                        <div>
                                            <div class="mb-4 font-bold text-lg"><i class="fas fa-spoon mr-1"></i> Déroulement de la recette</div>
                                            <p v-html="recette.deroulement"></p>

                                            <!-- Commentaires -->
                                            <div class="mt-10 bg-gray-50 bg-opacity-70 rounded-lg p-4 mb-4 border">
                                                <div class="mb-4 font-bold text-lg"><i class="fas fa-comments mr-1"></i> Commentaires</div>
                                                <div v-if="!displayVerifCommentaire">
                                                    <textarea v-model="commentaire" class="border p-3 rounded w-full bg-gray-100 text-sm focus:outline-none" rows="3" placeholder="Ecrire un commentaire" />
                                                    <vue-recaptcha
                                                    class="mb-4"
                                                    @verify="validCaptcha"
                                                    sitekey="6LfnOT0jAAAAAI2TyHZZfuFBDUSKkh7enOnKrizn"
                                                    ></vue-recaptcha>
                                                    <button @click="addCommentaire" class="px-4 py-2 rounded text-sm bg-gray-200 hover:bg-gray-300 duration-200">Valider <i class="fas fa-check ml-1"></i></button>
                                                </div>
                                                <div v-if="displayVerifCommentaire" class="bg-green-300 p-4 rounded-lg mt-2 text-sm">
                                                    <div class="text-lg mb-1"><i class="fas fa-check mr-1"></i> En cours de vérification</div>
                                                    <div>Afin d'éviter les spams, votre commentaire doit être vérifié par un administrateur avant sa publication</div>
                                                </div>
                                                <div class="border-t-2 mt-4 pt-4">
                                                    <div v-for="(commentaire, commentaireIndex) in recette.valid_commentaires" :key="'commentaire-' + commentaireIndex" class="pb-4 pb-4 mb-4 border-b">
                                                        <div>{{ commentaire.commentaire }}</div>
                                                        <div class="font-light text-xs mt-1">{{ commentaire.created_at | momentDateTime }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-2">
                                        <!-- Ingredients -->
                                        <div>
                                            <div class="mb-4 font-bold text-lg"><i class="fa-solid fa-cubes-stacked mr-1"></i> Ingrédients pour 25 petits fours: </div>
                                            <div class="text-sm">
                                                <p v-for="(ingredient, ingredientIndex) in recette.ingredients" :key="'ingredient-' + ingredientIndex">{{ ingredient }}</p>
                                            </div>
                                        </div>

                                        <!-- Ustensiles -->
                                        <div class="mt-10" v-if="recette.ustensiles.length > 0">
                                            <div class="mb-4 font-bold text-lg"><i class="fa-solid fa-blender mr-1"></i> Ustensiles utilisés </div>
                                            <a :href="ustensile.url ? ustensile.url : '#'" target="_blank" v-for="(ustensile, ustensileIndex) in recette.ustensiles" :key="'ustensile-' + ustensileIndex">
                                                <div class="py-2 border-b flex items-center" :class="ustensile.url ? 'hover:bg-gray-100 duration-200' : ''">
                                                    <img class="h-10 mr-3" :src="ustensile.image" alt="I-cook'in">
                                                    <div>
                                                        <div>{{ ustensile.nom }}</div>
                                                        <span v-if="ustensile.prix" class="text-xs text-default">{{ ustensile.prix }} €</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <!-- Tags -->
                                        <div class="mt-10">
                                            <div class="mb-4 font-bold text-lg"><i class="fa-solid fa-hashtag mr-1"></i> Tags </div>
                                            <div class="bg-gray-100 float-left mr-2 mb-2 text-xs font-light p-2 rounded">#Flexipan</div>
                                            <div class="bg-gray-100 float-left mr-2 mb-2 text-xs font-light p-2 rounded">#Gâteau</div>
                                            <div class="bg-gray-100 float-left mr-2 mb-2 text-xs font-light p-2 rounded">#Gouter</div>
                                            <div class="bg-gray-100 float-left mr-2 mb-2 text-xs font-light p-2 rounded">#Lait</div>
                                            <div class="bg-gray-100 float-left mr-2 mb-2 text-xs font-light p-2 rounded">#Prunes</div>
                                            <div class="bg-gray-100 float-left mr-2 mb-2 text-xs font-light p-2 rounded">#Pommes</div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Recettes similaires -->
                                <div v-if="recette.similaires.length > 0">
                                    <div class="font-semibold text-2xl border-b-2 pb-4 mb-4 mt-20">Ces recettes pourraient vous plaire</div>
                                    <div class="grid grid-cols-4 gap-10 mt-8">
                                        <div v-for="(similaire, similaireIndex) in recette.similaires" :key="'similaire-' + similaireIndex">
                                            <recette-card :recette="similaire" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Small screen -->
                            <!-- Large screen -->
                            <div class="md:hidden mt-20">
                                <div class="font-semibold text-2xl border-b-2 pb-4 mb-4">Réalisation</div>
                                <div class="mt-4">
                                    <!-- Ingredients -->
                                    <div class="border-b pb-4 mb-4">
                                        <div class="mb-4 font-bold text-lg"><i class="fa-solid fa-cubes-stacked mr-1"></i> Ingrédients pour 25 petits fours: </div>
                                        <div class="text-sm">
                                            <p v-for="(ingredient, ingredientIndex) in recette.ingredients" :key="'ingredient-' + ingredientIndex">{{ ingredient }}</p>
                                        </div>
                                    </div>

                                    <!-- Ustensiles -->
                                    <div class="pb-4 mb-4" v-if="recette.ustensiles.length > 0">
                                        <div class="mb-4 font-bold text-lg"><i class="fa-solid fa-blender mr-1"></i> Ustensiles utilisés </div>
                                        <a :href="ustensile.url ? ustensile.url : '#'" target="_blank" v-for="(ustensile, ustensileIndex) in recette.ustensiles" :key="'ustensile-' + ustensileIndex">
                                            <div class="py-2 border-b flex items-center" :class="ustensile.url ? 'hover:bg-gray-100 duration-200' : ''">
                                                <img class="h-10 mr-3" :src="ustensile.image" alt="I-cook'in">
                                                <div>
                                                    <div>{{ ustensile.nom }}</div>
                                                    <span v-if="ustensile.prix" class="text-xs text-default">{{ ustensile.prix }} €</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="mb-4 font-bold text-lg"><i class="fas fa-spoon mr-1"></i> Déroulement de la recette</div>
                                    <p v-html="recette.deroulement"></p>

                                    <!-- Commentaires -->
                                    <div class="border-t pt-4 mt-4">
                                        <div class="mb-4 font-bold text-lg"><i class="fas fa-comments mr-1"></i> Commentaires</div>
                                        <div v-if="!displayVerifCommentaire">
                                            <textarea v-model="commentaire" class="border p-3 rounded w-full bg-gray-100 text-sm focus:outline-none" rows="3" placeholder="Ecrire un commentaire" />
                                            <vue-recaptcha
                                            class="mb-4"
                                            @verify="validCaptcha"
                                            sitekey="6LfnOT0jAAAAAI2TyHZZfuFBDUSKkh7enOnKrizn"
                                            ></vue-recaptcha>
                                            <button @click="addCommentaire" class="px-4 py-2 rounded text-sm bg-gray-200 hover:bg-gray-300 duration-200">Valider <i class="fas fa-check ml-1"></i></button>
                                        </div>
                                        <div v-if="displayVerifCommentaire" class="bg-green-300 p-4 rounded-lg mt-2 text-sm">
                                            <div class="text-lg mb-1"><i class="fas fa-check mr-1"></i> En cours de vérification</div>
                                            <div>Afin d'éviter les spams, votre commentaire doit être vérifié par un administrateur avant sa publication</div>
                                        </div>
                                        <div class="border-t-2 mt-4 pt-4">
                                            <div v-for="(commentaire, commentaireIndex) in recette.valid_commentaires" :key="'commentaire-' + commentaireIndex" class="pb-4 pb-4 mb-4 border-b">
                                                <div>{{ commentaire.commentaire }}</div>
                                                <div class="font-light text-xs mt-1">{{ commentaire.created_at | momentDateTime }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Recettes similaires -->
                                <div v-if="recette.similaires.length > 0">
                                    <div class="font-semibold text-2xl border-b-2 pb-4 mb-4 mt-20">Ces recettes pourraient vous plaire</div>
                                    <div class="grid grid-cols-4 gap-10 mt-8">
                                        <div v-for="(similaire, similaireIndex) in recette.similaires" :key="'similaire-' + similaireIndex">
                                            <recette-card :recette="similaire" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Newsletter -->
                        <div class="w-full rounded-xl md:p-0 md:rounded-none" style="background-size: cover; background-position: center center;" :style="'background-image: url(' + config.image_newsletter + ')'">
                            <div class="w-full h-full py-28 px-5 rounded-xl md:px-0 md:rounded-none bg-black bg-opacity-70 text-white">
                                <div class="container mx-auto lg:w-5/6">
                                    <div class="md:grid grid-cols-2 gap-4 items-center">
                                        <div>
                                            <h2 class="text-3xl font-black">Rejoignez la newsletter</h2>
                                            <p class="font-light mt-2">En vous inscrivant à la newsletter vous acceptez de recevoir toutes les nouvelles recettes par email lors de leurs publications</p>
                                        </div>
                                        <newsletter-input class="mt-10 md:mt-0" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Atelier -->
                        <div class="pt-10 md:py-20">
                            <div class="container mx-auto lg:w-5/6">
                                <div class="flex items-center gap-4">
                                    <i class="fa-solid fa-kitchen-set text-default text-3xl"></i>
                                    <div class="text-xl font-thin">Ateliers Guy Demarle</div>
                                </div>
                
                                <div class="md:grid grid-cols-2 gap-14 mt-4 md:mt-10 items-center">
                                    <div>
                                        <h2 class="text-3xl font-bold">Participez à un atelier et découvrez les produits Guy Demarle</h2>
                                        <div class="flex gap-4 mt-4">
                                            <div class="bg-gray-100 rounded text-sm px-3 py-1.5"><i class="fas fa-euro mr-1"></i> Gratuit</div>
                                            <div class="bg-gray-100 rounded text-sm px-3 py-1.5"><i class="fas fa-ruler mr-1"></i> Sur mesure</div>
                                            <div class="bg-gray-100 rounded text-sm px-3 py-1.5"><i class="fas fa-person-walking-luggage mr-1"></i> A domicile</div>
                                        </div>
                                        <div class="text-xl font-light mt-4">Trouvons une date qui vous convienne pour réaliser un atelier. Ensemble, nous cuisinons la recette qui vous conviendra. Seul(e) ou avec vos amis, découvrez les produits <a class="text-default hover:underline cursor-pointer" target="_blank" href="https://www.guydemarle.com/">Guy Demarle</a> qui vous correspondent le mieux !</div>
                                        <div class="flex gap-2">
                                            <button @click="$router.push({name: 'contact'})" class="mt-5 bg-default px-4 py-2 font-light rounded">Prendre rendez-vous</button>
                                            <button @click="$router.push({name: 'atelier'})" class="mt-5 bg-gray-100 hover:bg-gray-200 duration-200 px-4 py-2 font-light rounded">En savoir plus</button>
                                        </div>
                                    </div>
                                    <div class="md:relative mt-10 md:-mt-10">
                                        <img class="w-full rounded md:absolute z-20" :src="config.image_atelier" alt="">
                                        <div class="flex justify-end">
                                            <div class="hidden md:block w-full h-[350px] -mr-5 -mt-5 bg-default opacity-40 rounded"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </app-template>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

import moment from 'moment'

import recetteCard from './../components/RecetteCard.vue'
import ImageSlider from './Components/ImageSlider.vue'
import RecettePdf from './Admin/Components/RecettePdf.vue'

export default {
    components: {
        VueRecaptcha,
        recetteCard,
        ImageSlider,
        RecettePdf
    },

    data() {
        return {
            old: false,
            displayRecette: true,
            recette: null,
            displayVerifCommentaire: false,
            commentaire: null,
            captchaIsValid: false,
        }
    },

    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },

    computed: {
        isAdmin() {
            return localStorage.getItem('admin')
        },
        facebookUrl() {
            return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href)
        },
        pinterestUrl() {
            if (this.recette && this.recette.images && this.recette.images.length > 0) {
                return "https://www.pinterest.com/pin/create/button/?url=" + encodeURIComponent('https://noviceencuisine.com') + "&media=" + encodeURIComponent(this.recette.images[0].url) + "&description=" + encodeURIComponent(this.recette.description.replace(/<[^>]+>/g, ''));
            }

        }
    },

    methods: {
        removePopUp() {
            this.displayRecette = true
            localStorage.setItem('display-recette-popup', 1)
        },

        async getRecette() {
            let response = await this.$request.post('/recette/get', {
                slug: this.$route.params.slug
            })
            if (!response.data.success) {
                this.$toast.error('Recette introuvable')
                this.$router.push({name: 'home'})
                return
            }
            if (!this.isAdmin && !response.data.api.display) {
                this.$toast.error('Recette introuvable')
                this.$router.push({name: 'home'})
                return
            }
            this.recette = response.data.api
            this.recette.imgUrls = this.recette.images.map(function(obj) {return obj['url']})
            this.old = this.recette.old
        },

        printRecette() {
            // this.$eventHub.$emit('print-recette', this.recette.url_slug)
        },

        async addCommentaire() {
            if (!this.commentaire) {
                return
            }
            if (!this.captchaIsValid) {
                this.$toast.error('Merci de valider le captcha')
                return
            }
            let response = await this.$request.post('/commentaire/add', {
                recette_id: this.recette.id,
                commentaire: this.commentaire
            })
            if (response.data.success) {
                this.commentaire = null
                this.displayVerifCommentaire = true
            }
        },
        validCaptcha() {
            this.captchaIsValid = true
        }
    },

    mounted () {
        // if (localStorage.getItem('display-recette-popup')) {
        //     this.displayRecette = true
        // }

        this.getRecette()
    },
}
</script>

<style>
.old-recipe {
    text-align: center
}

.old-recipe img {
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.new-recipe p {
    margin-bottom: 10px;;
}
</style>